import React from 'react'

export const ThumbsUp = ({className, onClick}) => {
	return (
		<svg className={className} onClick={onClick} width="22" height="21" viewBox="0 0 22 21" fill="none">
			<path d="M13.6 7.9999H20C20.5304 7.9999 21.0391 8.21062 21.4142 8.58569C21.7893 8.96076 22 9.46947 22 9.9999V12.1039C22.0003 12.3653 21.9493 12.6241 21.85 12.8659L18.755 20.3809C18.6795 20.5641 18.5513 20.7208 18.3866 20.831C18.2219 20.9412 18.0282 21 17.83 20.9999H1C0.734784 20.9999 0.48043 20.8945 0.292893 20.707C0.105357 20.5195 0 20.2651 0 19.9999V9.9999C0 9.73469 0.105357 9.48033 0.292893 9.2928C0.48043 9.10526 0.734784 8.9999 1 8.9999H4.482C4.6421 8.99994 4.79986 8.96155 4.94203 8.88794C5.0842 8.81433 5.20662 8.70766 5.299 8.5769L10.752 0.849904C10.8209 0.752217 10.9226 0.682464 11.0385 0.653294C11.1545 0.624124 11.277 0.637467 11.384 0.690904L13.198 1.5979C13.7085 1.85307 14.1163 2.27522 14.3537 2.79422C14.5911 3.31322 14.6438 3.89783 14.503 4.4509L13.6 7.9999ZM6 10.5879V18.9999H17.16L20 12.1039V9.9999H13.6C13.2954 9.99986 12.9948 9.93024 12.7212 9.79636C12.4476 9.66248 12.2082 9.46787 12.0213 9.22739C11.8343 8.98691 11.7048 8.7069 11.6425 8.40873C11.5803 8.11056 11.5869 7.80211 11.662 7.5069L12.565 3.9589C12.5933 3.84823 12.5828 3.73122 12.5353 3.62733C12.4878 3.52345 12.4062 3.43895 12.304 3.3879L11.643 3.0579L6.933 9.7299C6.683 10.0839 6.363 10.3739 6 10.5879V10.5879ZM4 10.9999H2V18.9999H4V10.9999Z" fill="#2F1152"/>
		</svg>
	)
}

export const ThumbsDown = ({className, onClick}) => {
	return (
		<svg className={className} onClick={onClick} width="22" height="21" viewBox="0 0 22 21" fill="none">
			<path d="M8.4 13H2C1.46957 13 0.96086 12.7893 0.585788 12.4142C0.210715 12.0391 1.0643e-06 11.5304 1.0643e-06 11V8.896C-0.000268556 8.63464 0.050692 8.37576 0.150001 8.134L3.246 0.62C3.32128 0.436753 3.4493 0.279995 3.6138 0.169612C3.77831 0.0592292 3.97189 0.00019547 4.17 0H21C21.2652 0 21.5196 0.105357 21.7071 0.292893C21.8946 0.48043 22 0.734784 22 1V11C22 11.2652 21.8946 11.5196 21.7071 11.7071C21.5196 11.8946 21.2652 12 21 12H17.518C17.3579 12 17.2001 12.0384 17.058 12.112C16.9158 12.1856 16.7934 12.2922 16.701 12.423L11.248 20.149C11.1791 20.2467 11.0774 20.3164 10.9615 20.3456C10.8455 20.3748 10.723 20.3614 10.616 20.308L8.802 19.4C8.2915 19.1448 7.88367 18.7227 7.64627 18.2037C7.40887 17.6847 7.3562 17.1001 7.497 16.547L8.4 13ZM16 10.412V2H4.84L2 8.896V11H8.4C8.7046 11 9.00517 11.0697 9.27877 11.2035C9.55237 11.3374 9.79178 11.532 9.97873 11.7725C10.1657 12.013 10.2952 12.293 10.3575 12.5912C10.4197 12.8893 10.4131 13.1978 10.338 13.493L9.435 17.041C9.40674 17.1517 9.41722 17.2687 9.46471 17.3726C9.51219 17.4765 9.59382 17.561 9.696 17.612L10.357 17.942L15.067 11.27C15.317 10.916 15.637 10.626 16 10.412ZM18 10H20V2H18V10Z" fill="#2F1152"/>
		</svg>
	)
}