import React from 'react'
import { Link } from "gatsby"
import * as styles from './style.module.scss'

/**
 * Render a button/anchor component
 * @param {string - button|submit|reset} type
 * @param {string - https://*} href
 * @param {string} label
 * @returns 
 */
const Button = ({type, onClick, href, external, label, color, className, downloadable}) => {
	if ( type && href ) throw new Error("[BUILD ERR]: Button component cannot contain type and href props simultaneously.");

	if ( href && !external ) {
		return (
			<Link
				to={href ?? "javascript:void(0);"}
				className={`${className} ${styles.button} ${styles['button__block']} ${styles[`button--${color ?? 'yellow'}`]}`}
				data-content={label}
				onClick={onClick}
			>
				{label}
			</Link>
		)
	}

	if ( href && external ) {
		return (
			<a
				href={href ?? "javascript:void(0);"}
				className={`${className} ${styles.button} ${styles['button__block']} ${styles[`button--${color ?? 'yellow'}`]}`}
				data-content={label}
				download={downloadable ?? false}
				onClick={onClick}
			>
				{label}
			</a>
		)
	}

	return (
		<button
			type={type ?? "button"}
			className={`${className} ${styles.button} ${styles['button__block']} ${styles[`button--${color ?? 'yellow'}`]}`}
			data-content={label}
			onClick={onClick}
		>
			{label}
		</button>
	)
}

export default Button