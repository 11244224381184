import React, { useEffect, useState } from 'react'
import { Container, RoundedBox, FadedStar } from '../'
import Slider from 'react-slick' 
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as styles from './style.module.scss'

import { useLocomotiveScroll } from 'react-locomotive-scroll'

const HowItWorks = ({data, active}) => {
	if ( typeof window !== 'undefined' ) {
		if ( window.innerWidth <= 1024 ) {
			active = true;
		}
	}

	const [ chatBubbles, setChatBubbles ] = useState([])
	const [ activeCarousel, setActiveCarousel ] = useState(false)
	const [ isTyping, setIsTyping ] = useState({is: true, side: "left"})
	const [ alreadyPlayed, setAlreadyPlayed ] = useState(false) 

	const { scroll } = useLocomotiveScroll();

	const carouselSettings = {
		autoPlay: true,
		arrows: false,
		dots: true,
		infinite: false,
		speed: 100,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true
	}

	let injectBubbles;
	const conversationAnimationHandler = (shouldStop) => {
		if ( alreadyPlayed ) return
		if ( activeCarousel ) return

		const bubblesAmount = data?.chats.length;
		let selectedBubble = 0;

		function isOdd(number) {
			return number % 2
		}

		injectBubbles = setInterval(function() {
			if ( isOdd(selectedBubble) === 1 ) {
				setIsTyping({is: true, side: "left"})
			} else {
				setIsTyping({is: true, side: "right"})
			}

			setChatBubbles(chatBubbles => {
				if ( chatBubbles.length > 3 ) {
					chatBubbles.shift();
				}

				return [...chatBubbles, data.chats[selectedBubble]]
			});
			++selectedBubble

			if ( selectedBubble === bubblesAmount ) {
				setIsTyping({is: false, side: "left"})

				setTimeout(function() {
					setActiveCarousel(true)
				}, 2000)

				if ( window.innerWidth > 1024 ) {
					scroll.start();
				}
				return clearInterval(injectBubbles)
			}
		}, Math.floor(Math.random() * (2000 - 2000 + 1) + 2000))
	}

	const checkWindowScroll = () => {
		let scrolling = false
		let preventPlaying = false

		if ( typeof window !== 'undefined' ) {
			window.addEventListener('scroll', function() {
				if ( alreadyPlayed ) return;

				if ( window.scrollY > 1500 ) {
					scrolling = true
					setAlreadyPlayed(true)
				}
			})
		}

		setInterval(() => {
			if ( scrolling && !preventPlaying ) {
				scrolling = false
				preventPlaying = true
				return conversationAnimationHandler(false)
			}
		}, 1000)
	}

	// useEffect(() => {
	// 	checkWindowScroll();

	// 	if ( active && window.innerWidth > 1024 ) {
	// 		if ( !alreadyPlayed ) {
	// 			scroll.scrollTo('#howItWorks', {
	// 				offset: -150
	// 			})
	
	// 			scroll.stop();
	// 		}

	// 		let scrollCount = 0;
	// 		let selectedBubble = 0;
	// 		const bubblesAmount = data?.chats.length;
	// 		let delta = 0;

	// 		function handleBubblesAnimation(delta) {
	// 			if ( alreadyPlayed || delta < 0 ) return;

	// 			++scrollCount;

	// 			if ( bubblesAmount === selectedBubble ) {
	// 				setAlreadyPlayed(true);
	// 				setActiveCarousel(true);
	// 				return scroll.start();	
	// 			}

	// 			setChatBubbles(chatBubbles => {
	// 				if ( chatBubbles.length > 3 ) {
	// 					chatBubbles.shift();
	// 				}
	
	// 				return [...chatBubbles, data.chats[selectedBubble]]
	// 			});
	// 			++selectedBubble;
	// 		}

	// 		let scrolling = false;
	// 		window.addEventListener('wheel', (e) => {
	// 			scrolling = true;
	// 			delta = e.deltaY;
	// 		})

	// 		setInterval(() => {
	// 			if ( scrolling ) {
	// 				scrolling = false;
	// 				handleBubblesAnimation();
	// 			}
	// 		}, 500)
	// 	}
	// }, [active])

	return (
		<section id="howItWorks" data-scroll className={`${styles.how} ${active ? styles.howActive : ''}`}>
			<FadedStar
				className={styles.fadedStar}
				position={{
					left: "-33%",
					top: "118px"
				}}
			/>

			{/* <Glow
				className={styles.glowYellow}
				color="var(--color-yellow)"
				width="200px"
				height="200px"
				blur="80px"
				position={{
					top: "100px",
					left: "-100px"
				}}
			/> */}

			<Container>
				<h1 className={styles.title}>{data.heading}</h1>

				{/* <div className={`${styles.conversation} ${activeCarousel ? styles.conversationInactive : ''}`}>
					<div className="flex align-center justify-center">
						<div className={styles.conversation__column}>
							<img className={styles.conversation__guy} src={data.bettors.left.localFile?.childImageSharp?.gatsbyImageData.images.fallback.src} alt="Bettor 01" />
						</div>
						<div className={`${styles.conversation__column} ${styles.conversation__bubbles}`}>
							{
								chatBubbles && active &&
								chatBubbles.map(chat => 
									<>
										<span
											key={Math.round()}
											className={`${styles.bubble} ${chat.side === "left" ? styles.bubblePurple : styles.bubbleWhite}`}
										>
											{chat.content}
										</span>
										<div className="clearfix"></div>
									</>
								)
							}

							<span
								className={`${styles.bubble} ${styles.bubbleTyping}`}
								style={{
									float: isTyping.side,
									display: isTyping.is ? 'block' : 'none'
								}}
							>
								<span></span>
								<span></span>
								<span></span>
							</span>
						</div>
						<div className={styles.conversation__column}>
							<img className={`${styles.conversation__guy} ${styles.conversation__guyRight}`} src={data.bettors.right.localFile?.childImageSharp?.gatsbyImageData.images.fallback.src} alt="Bettor 02" />
						</div>
					</div>
				</div> */}

				<div className={`${styles.slider} ${styles.sliderActive}`}>
					<Slider {...carouselSettings}>
						{/* Feature 1 */}
						<div key={Math.random()}>
							<div className={`${styles.slide} ${styles.slide__content}`}>
								<img className={styles.base__image} src={data.features.feature1.screen.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="" />
								<img className={styles.base__image} src={data.features.feature1.screenMobile.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="" />
								
								<div className={styles.content}>
									<div>
										<h2>{data.features.feature1.heading}</h2>
										<div dangerouslySetInnerHTML={{__html: data.features.feature1.content}}></div>
									</div>
								</div>
							</div>
						</div>

						{/* Feature 2 */}
						<div key={Math.random()}>
							<div className={`${styles.slide} ${styles.slide__content}`}>
								<img className={styles.base__image} src={data.features.feature2.screen.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="" />
								<img className={styles.base__image} src={data.features.feature2.screenMobile.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="" />
								
								<div className={styles.content}>
									<div>
										<h2>{data.features.feature2.heading}</h2>
										<div dangerouslySetInnerHTML={{__html: data.features.feature2.content}}></div>
									</div>
								</div>
							</div>
						</div>

						{/* Feature 3 */}
						<div key={Math.random()}>
							<div className={`${styles.slide} ${styles.slide__content}`}>
								<img className={styles.base__image} src={data.features.feature3.screen.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="" />
								<img className={styles.base__image} src={data.features.feature3.screenMobile.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="" />

								<div className={styles.content}>
									<div>
										<h2>{data.features.feature3.heading}</h2>
										<div dangerouslySetInnerHTML={{__html: data.features.feature3.content}}></div>
									</div>
								</div>
							</div>
						</div>

						{/* Feature 4 */}
						<div key={Math.random()}>
							<div className={`${styles.slide} ${styles.slide__content}`}>
								<img className={styles.base__image} src={data.features.feature4.screen.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="" />
								<img className={styles.base__image} src={data.features.feature4.screenMobile.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="" />
								
								<div className={styles.content}>
									<div>
										<h2>{data.features.feature4.heading}</h2>
										<div dangerouslySetInnerHTML={{__html: data.features.feature4.content}}></div>
									</div>
								</div>
							</div>
						</div>
					</Slider>
				</div>
			</Container>

			{/* <Glow
				className={styles.glowPurple}
				color="var(--color-primary)"
				width="300px"
				height="300px"
				blur="100px"
				position={{
					bottom: "-350px",
					right: "30px"
				}}
			/> */}

			<RoundedBox
				className={styles.roundedBox}
				transform="rotate(-152deg)"
				width="753px"
				height="914px"
				position={{
					top: "90px",
					right: "-580px"
				}}
			/>
		</section>
	)
}

export default HowItWorks