const serializeFormData = (form) => {
	let serialized = []

	for ( let i = 0; i < form.elements.length; ++i ) {
		let field = form.elements[i]


		if ( !field.name || field.type === "submit" ) continue;

		serialized.push({
			name: field.name,
			value: field.value
		})
	}

	return serialized
}

export default serializeFormData
