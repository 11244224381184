import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"

import { Context } from "../Context"
import { Theme } from "../index"
import { Tokens } from "../Theme/Tokens"

const ChatBubbles = () => {
//   const data = useStaticQuery(graphql`{
//   wp: wpPage(slug: {eq: "contact"}) {
//     contactFields {
//       contentFields {
//         languageValue {
//           slug
//           name
//         }
//         heroContentImage {
//           localFile {
//             childImageSharp {
//               gatsbyImageData(
//                 width: 600
//                 quality: 100
//                 placeholder: NONE
//                 layout: CONSTRAINED
//               )
//             }
//           }
//         }
//         heroContentHeading
//         heroContentNumber
//         heroContentCopy
//       }
//     }
//   }
// }
// `)

  return (
    {/*  <Context.Consumer>
      {({ language }) => {
        const fields = data.wp.contactFields.contentFields.find(row =>
          row.languageValue.slug.includes(language)
        )

        return (
          <Theme.Wrap>
            <Container>
              <ImgContainer>
                <GatsbyImage
                  image={fields.heroContentImage.localFile.childImageSharp.gatsbyImageData}
                  alt="Wanna Chat?" />
              </ImgContainer>
              <Content>
                <h2>{fields.heroContentHeading}</h2>
                <h3>{fields.heroContentNumber}</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: fields.heroContentCopy }}
                />
              </Content>
            </Container>
          </Theme.Wrap>
        );
      }}
    </Context.Consumer> */}
  )
}

export default ChatBubbles

const Container = styled.div`
  padding: calc(${Tokens.rhythm} * 3) 0;

  & > * {
    width: 100%;
  }

  @media (min-width: ${Tokens.screen.medium}) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * {
      width: 40%;
    }
  }
`

const ImgContainer = styled.div`
  width: 60%;
  margin: 0 auto calc(${Tokens.rhythm} * 2);

  @media (min-width: ${Tokens.screen.medium}) {
    width: 40%;
    margin: 0 auto;
  }
`

const Content = styled.div`
  h2 {
    font-size: ${Tokens.font.size.ultraMobile};
    color: ${Tokens.color.default};
    text-align: center;
  }

  h3 {
    font-size: ${Tokens.font.size.ultraMobile};
    color: ${Tokens.color.purple};
    text-align: center;
  }

  @media (min-width: ${Tokens.screen.medium}) {
    h2 {
      font-size: ${Tokens.font.size.ultra};
      text-align: left;
    }

    h3 {
      font-size: ${Tokens.font.size.ultra};
      text-align: left;
    }
  }
`
