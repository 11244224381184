import React, { useRef } from 'react'
import Slider from 'react-slick'
import { Container, FadedStar, RoundedBox } from '../'
import { Link, navigate } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { ArrowRight } from '../../assets/vectors'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as styles from './style.module.scss'

const BlogEntry = ({data, language, active}) => {
	const { betting, blogPosts } = data;
	
	let trendingCategories = []
	if ( betting.links ) {
		trendingCategories = Object.values(betting.links[0]);
	}

	const carouselSettings = {
		autoPlay: true,
		arrows: false,
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	}

	const mainImage = useRef()
	const changeMainImageOnHover = (e) => {
		mainImage.current.style.opacity = 0;

		setTimeout(function() {
			mainImage.current.setAttribute('src', e.target.getAttribute('data-image'))
			mainImage.current.style.opacity = 1;
		}, 100);
	}

	return (
		<section className={`${styles.blog} ${active ? styles.blogActive : ''}`}>
			<div data-scroll data-scroll-speed="2" data-scroll-direction="vertical">
				<RoundedBox
					transform="rotate(26deg)"
					width="695px"
					height="930px"
					position={{
						top: "30px",
						left: "-1080px"
					}}
					className={styles.roundedBox}
				/>
			</div>

			<Container>
				<div className="flex">
					<div className={styles.image}>
						<img ref={mainImage} src={data.betting.image.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="" />
					</div>
					<div className={styles.content}>
						<div className={styles.title}>
							<h1>{data.betting.heading}</h1>

							{
								trendingCategories &&
								<ul className="flex align-bottom">
									{
										trendingCategories.map(category =>
											<li key={category}><Link to={`/${language.split('_')[0] ?? "pt"}/blog/${encodeURI(category.toLowerCase())}/`} className="pill">{category}</Link></li>
										)
									}
								</ul>
							}
						</div>

						{
							blogPosts &&
							<div className={styles.posts}>
								{
									blogPosts.map(({node}) =>
										<article data-image={node.blogFields.blogFields[0].blogImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} onMouseEnter={changeMainImageOnHover} key={node.slug} className={styles.post} onClick={() => navigate(`/${language.split('_')[0] ?? "pt"}/blog/${node.categories.nodes[0].slug}/${node.slug}/`)}>
											<h1>{node.blogFields.blogFields[0].blogTitle}</h1>
											<span className="pill" onClick={() => navigate(`/${language.split('_')[0] ?? "pt"}/blog/${node.categories.nodes[0].slug}`)}>{node.categories.nodes[0].name}</span>
											<p>{node.blogFields.blogFields[0].excerpt}</p>
											<Link to={`/${language.split('_')[0] ?? "pt"}/blog/${node.categories.nodes[0].slug}/${node.slug}/`}>leia mais<ArrowRight /></Link>
										</article>
									)
								}
							</div>
						}

						{
							blogPosts &&
							<div className={styles.posts__mobile}>
								<Slider {...carouselSettings}>
									{
										blogPosts.map(({node}) =>
											<div>
												<article key={node.slug} className={styles.post__mobile}>
													<div className={styles.image__wrapper}>
														<div className={styles.image__mobile}>
															<GatsbyImage
																image={node.blogFields.blogFields[0].blogImage.localFile.childImageSharp.gatsbyImageData}
																alt="Betting Redefined"
															/>
														</div>

														<div>
															<h1>{node.blogFields.blogFields[0].blogTitle}</h1>
															<span className="pill" onClick={() => navigate(`/${language.split('_')[0] ?? "pt"}/blog/${node.categories.nodes[0].slug}`)}>{node.categories.nodes[0].name}</span>
														</div>
													</div>

													<p>{node.blogFields.blogFields[0].excerpt}</p>
													<Link to={`/${language.split('_')[0] ?? "pt"}/blog/${node.categories.nodes[0].slug}/${node.slug}/`}>leia mais<ArrowRight /></Link>
												</article>
											</div>
										)
									}
								</Slider>
							</div>
						}
					</div>
				</div>
			</Container>

			<FadedStar
				className={styles.fadedStar}
				position={{
					right: "-33%",
					top: "118px"
				}}
			/>
		</section>
	)
}

export default BlogEntry
