import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Context } from "../Context"
import { Theme } from "../../components"
import * as Styles from "./styles"


const VideoModal = ({ isVisible }) => {
  const data = useStaticQuery(graphql`
    {
      wp: wpPage(slug: { eq: "home" }) {
        homePageFields {
          heroSection {
            languageValue {
              name
              slug
            }
            bottom {
              videoLink
              videoTitle
            }
          }
        }
      }
    }
  `)

  return (
    <Context.Consumer>
      {({ language, toggleVideoModalIsVisible }) => {
        const fields = data.wp.homePageFields.heroSection.find(row =>
          row.languageValue.slug.includes(language)
        )
        return isVisible ? (
          <>
            <Styles.Modal>
              <iframe
                src={fields.videoLink}
                title={fields.videoTitle}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </Styles.Modal>
            <Styles.Close onClick={() => toggleVideoModalIsVisible(false)}>
            </Styles.Close>
            <Styles.ModalBackdrop
              onClick={e => {
                e.preventDefault()
                toggleVideoModalIsVisible(false)
              }}
            ></Styles.ModalBackdrop>
          </>
        ) : null
      }}
    </Context.Consumer>
  )
}


export default VideoModal
