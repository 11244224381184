import React, { useState, useEffect } from 'react'
import Slider from 'react-slick' 
import { Container } from '../'
import { Link } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as styles from './style.module.scss'

const AdsCarousel = ({data, hasContainer}) => {
	return (
		<section className={styles.ads}>
			{
				hasContainer ?
					<Container>
						<Content data={data} />
					</Container>
				: <Content data={data} />
			}
		</section>
	)
}

const Content = ({data}) => {
	const [ windowSize, setWindowSize ] = useState(1200);

	let dataType = []

	if ( data.banners ) {
		dataType = data.banners
	} else {
		dataType = data.homePageFields?.sectionPlay[1].banners
	}
	
	const carouselSettings = {
		autoPlay: true,
		arrows: false,
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	}

	useEffect(() => {
		if ( typeof window !== 'undefined' ) {
			if ( window.innerWidth <= 1024 ) {
				setWindowSize(window.innerWidth);
			}
		}
	}, []);

	return (<>
		{
			dataType &&
			<Slider {...carouselSettings}>
				{
					dataType.map(banner => {
						if ( windowSize <= 1024 ) {
							return <Link key={banner.linkUrl} to={banner.linkUrl} className={styles.image__wrapper}>
											 <img src={banner.imageMobile.localFile.childImageSharp.gatsbyImageData.images.fallback.src}/>
										 </Link>
						} else {
							return <Link key={banner.linkUrl} to={banner.linkUrl} className={styles.image__wrapper}>
											 <img src={banner.image.localFile.childImageSharp.gatsbyImageData.images.fallback.src}/>
										 </Link>
						}
					})
				}
			</Slider>
		}
	</>)
}

export default AdsCarousel