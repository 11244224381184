import React from 'react'

export const WannaLogo = ({onClick, className, hasText}) => {
	 if ( hasText ) {
	 	return (
			
			<svg width={200} viewBox="0 0 456 107.69">
			<g>
				<rect class="cls-1" x="11.01" y="11.01" width="85.67" height="85.67" rx="13.41" ry="13.41" transform="translate(-17.4 80.74) rotate(-65.68)" style={{fill: "#fbd547"}}/>
				<g>
					<rect class="cls-3" x="11.01" y="11.01" width="85.67" height="85.67" rx="13.41" ry="13.41" transform="translate(-15.55 22.48) rotate(-20.68)" style={{fill: "#6c449b"}}/>
					<path class="cls-2" d="M76.45,39.77l-9.62,29.03h-5.72l-7.17-21.48-7.3,21.48h-5.76l-9.62-29.03h5.6l7.22,22.06,7.51-22.06h4.98l7.34,22.18,7.42-22.18h5.14Z" style={{fill: "#fff"}}/>
				</g>
			</g>
			<g>
				<path style={{fill: "#fff"}} class="cls-2" d="M180.02,37.05l-11.23,33.6h-5.04l-9.17-26.83-9.17,26.83h-5.14l-11.23-33.6h4.94l9.07,27.31,9.46-27.31h4.42l9.26,27.46,9.26-27.46h4.56Z"/>
				<path style={{fill: "#fff"}} class="cls-2" d="M206.22,62.25h-17.86l-3.7,8.4h-4.94l15.22-33.6h4.75l15.27,33.6h-5.04l-3.7-8.4Zm-1.68-3.84l-7.25-16.46-7.25,16.46h14.5Z"/>
				<path style={{fill: "#fff"}} class="cls-2" d="M248.85,37.05v33.6h-3.94l-20.16-25.06v25.06h-4.8V37.05h3.94l20.16,25.06v-25.06h4.8Z"/>
				<path style={{fill: "#fff"}} class="cls-2" d="M287.82,37.05v33.6h-3.94l-20.16-25.06v25.06h-4.8V37.05h3.94l20.16,25.06v-25.06h4.8Z"/>
				<path style={{fill: "#fff"}} class="cls-2" d="M319.31,62.25h-17.86l-3.7,8.4h-4.94l15.22-33.6h4.75l15.27,33.6h-5.04l-3.7-8.4Zm-1.68-3.84l-7.25-16.46-7.25,16.46h14.5Z"/>
				<path style={{fill: "#fff"}} class="cls-2" d="M331.79,67.62c0-1.92,1.49-3.26,3.26-3.26s3.17,1.34,3.17,3.26-1.44,3.31-3.17,3.31-3.26-1.39-3.26-3.31Z"/>
				<path style={{fill: "#fff"}} class="cls-2" d="M341.82,53.85c0-9.94,7.58-17.19,17.81-17.19,5.18,0,9.7,1.78,12.77,5.23l-3.12,3.02c-2.59-2.74-5.76-3.98-9.46-3.98-7.58,0-13.2,5.47-13.2,12.91s5.62,12.91,13.2,12.91c3.7,0,6.86-1.3,9.46-4.03l3.12,3.02c-3.07,3.46-7.58,5.28-12.82,5.28-10.18,0-17.76-7.25-17.76-17.19Z"/>
				<path style={{fill: "#fff"}} class="cls-2" d="M375.8,53.85c0-9.84,7.58-17.19,17.91-17.19s17.81,7.3,17.81,17.19-7.58,17.19-17.81,17.19-17.91-7.34-17.91-17.19Zm30.91,0c0-7.44-5.57-12.91-13.01-12.91s-13.11,5.47-13.11,12.91,5.57,12.91,13.11,12.91,13.01-5.47,13.01-12.91Z"/>
				<path style={{fill: "#fff"}} class="cls-2" d="M450.01,70.65l-.05-24.48-12.15,20.4h-2.21l-12.15-20.26v24.34h-4.61V37.05h3.94l14.02,23.62,13.83-23.62h3.94l.05,33.6h-4.61Z"/>
			</g>
		</svg>
		
	)
	}

	return (
		<svg width="67" height="68" viewBox="0 0 67 68" fill="none">
			<path d="M17.2372 5.50101L1.03142 39.0857C-1.00738 43.3109 0.728538 48.4067 4.9087 50.4674L38.1354 66.8478C42.3156 68.9085 47.3571 67.1539 49.3959 62.9287L65.6016 29.344C67.6404 25.1189 65.9045 20.0231 61.7244 17.9623L28.4976 1.58196C24.3175 -0.47881 19.276 1.27581 17.2372 5.50101Z" fill="#F5CC45"/>
			<path d="M41.4859 2.26599L6.74726 15.0461C2.37688 16.6539 0.123492 21.5383 1.71418 25.9558L14.358 61.0687C15.9487 65.4862 20.7811 67.7638 25.1515 66.156L59.8902 53.376C64.2606 51.7681 66.5139 46.8837 64.9233 42.4662L52.2794 7.35329C50.6887 2.93583 45.8563 0.65817 41.4859 2.26599Z" fill="#7319C1"/>
			<path d="M41.4859 2.26599L6.74726 15.0461C2.37688 16.6539 0.123492 21.5383 1.71418 25.9558L14.358 61.0687C15.9487 65.4862 20.7811 67.7638 25.1515 66.156L59.8902 53.376C64.2606 51.7681 66.5139 46.8837 64.9233 42.4662L52.2794 7.35329C50.6887 2.93583 45.8563 0.65817 41.4859 2.26599Z" fill="url(#paint0_linear_3233_53477)"/>
			<path d="M41.4859 2.26599L6.74726 15.0461C2.37688 16.6539 0.123492 21.5383 1.71418 25.9558L14.358 61.0687C15.9487 65.4862 20.7811 67.7638 25.1515 66.156L59.8902 53.376C64.2606 51.7681 66.5139 46.8837 64.9233 42.4662L52.2794 7.35329C50.6887 2.93583 45.8563 0.65817 41.4859 2.26599Z" fill="#6B26C1"/>
			<path d="M47.5738 25.2749L41.4779 43.6961H37.8801L33.4339 30.0708L28.7206 43.6961H25.107L19.1211 25.2749H22.6404L27.1494 39.2813L31.8628 25.2749H35.005L39.624 39.3607L44.3374 25.2749H47.5738Z" fill="white"/>
			<defs>
			<linearGradient id="paint0_linear_3233_53477" x1="67.8034" y1="50.4647" x2="-1.8794" y2="48.8559" gradientUnits="userSpaceOnUse">
				<stop stopColor="#9339E9"/>
				<stop offset="1" stopColor="#3E2794"/>
			</linearGradient>
			</defs>
		</svg>
	)
}