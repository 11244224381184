
export const Tokens = {
  border: {
    radius: `4px`,
  },
  gradient: {
    purple_linear: `linear-gradient(157.36deg, #9816F6 -34.79%, #3E2794 73.85%)`,
  },
  color: {
    white: `#FFFFFF`,
    black: `#000000`,
    default: `#3E3E3E`,
    dark: `#51455E`,
    light: `#e1e1e1`,
    border: `#bfbfbf`,
    purple: `#6917c1`,
    dark_purple: `#301254`,
    gold: `#e9a43c`,
    backdrop: `rgba(105, 23, 193, 0.4)`,
    yellow: `#F5CC45`,
  },
  font: {
    family: {
      mont: `"Montserrat", sans-serif`,
      barlow: `"Barlow Condensed", sans-serif`,
    },
    size: {
      micro: `10px`,
      small: `13px`,
      subBase: `15px`,
      base: `16px`,
      large: `18px`,
      super: `20px`,
      extra: `22px`,
      ultra: `45px`,
      superUltra: `48px`,
      ultraMobile: `30px`,
      ridiculous: `200px`,
      ridiculousMobile: `120px`,
    },
    style: `normal`,
    lineHeight: `24px`,
    weight: {
      light: `300`,
      normal: `400`,
      bold: `600`,
      black: `900`,
    },
  },
  rhythm: `24px`,
  screen: {
    medium: `1140px`,
    large: `1400px`,
  },
  wrap: {
    wide: `1200px`,
    narrow: `800px`,
    extra_narrow: `590px`,
  },
  z: {
    bottom: -2,
    behind: -1,
    baseline: 0,
    header: 1,
    band: 2,
    language: 3,
    modal: 4,
  },
}
