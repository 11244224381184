// extracted by mini-css-extract-plugin
export var stories = "style-module--stories--2T7VX";
export var title = "style-module--title--3f8Kg";
export var content = "style-module--content--1KheZ";
export var slide = "style-module--slide--1cL6f";
export var content__group = "style-module--content__group--2KvIG";
export var headline = "style-module--headline--1QGCk";
export var button = "style-module--button--15GGf";
export var link__alternate = "style-module--link__alternate--hbktO";
export var sliderParent = "style-module--slider-parent--23E4L";
export var additionalStoryData = "style-module--additional-story-data--2hxF6";
export var isWinnersPage = "style-module--isWinnersPage--3qMDg";
export var additionalStoryDataActive = "style-module--additional-story-data-active--CIk8o";
export var backdrop = "style-module--backdrop--1bfSp";
export var close = "style-module--close--2hfLf";
export var isActive = "style-module--is-active--8Toe1";
export var fadedStar = "style-module--fadedStar--2_2Y3";