import React from 'react'
import Button from '../Buttons'
import { Context } from "../Context"
import { Link, graphql, useStaticQuery } from "gatsby"
import { WannaLogo, Globe, InstagramIcon, DownloadIcon, TwitterIcon, YoutubeIcon } from '/src/assets/vectors'
import * as styles from './style.module.scss'

import AndroidStoreImage from '/src/assets/img/androidstore.png'
import AppStoreImage from '/src/assets/img/appstore.png'

const Navbar = ({hasScrolled, onUpdate, language}) => {
	const [ dropdownOpen, setDropdownOpen ] = React.useState(false)
	const [ mobileOpen, setMobileOpen ] = React.useState(false)

	const navbar = React.useRef()

	const handleScroll = (e) => {
		if ( window.innerWidth <= 1023 ) {
			return
		}

		let top = window.pageXOffset || document.documentElement.scrollTop;

		if ( top > 0 ) {
			return navbar.current.classList.add(styles.navbarPurple)
		}

		navbar.current.classList.remove(styles.navbarPurple);
	}

	const handleLanguageChange = (lang) => {
		onUpdate(lang)
	}

	React.useEffect(() => {
		window.addEventListener('scroll', handleScroll)

		if ( hasScrolled ) {
			navbar.current.classList.add(styles.navbarPurple)
		} else {
			navbar.current.classList.remove(styles.navbarPurple)
		}
	}, [hasScrolled])

	const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "header-logo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      globe: file(relativePath: { eq: "header-language.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      wp: wpPage(slug: { eq: "header" }) {
        headerFields {
          headerFields {
            languageValue {
              name
              slug
            }
            linkGroup {
              label
              link
              color
            }
            buttonLabel
          }
        }
      }
    }
  `)

	return (
		<Context.Consumer>
      {({
        language,
				downloadModalIsVisible,
			 	toggleDownloadModalIsVisible,
      }) => {
        const fields = data.wp.headerFields.headerFields.find(row =>
          row.languageValue.slug.includes(language)
        )
				return (
					<nav className={`${styles.navbar} flex align-center ${mobileOpen ? styles.navbar__mobileActive : ""}`} ref={navbar}>
						<div className={`${styles['navbar__group']} flex justify-start align-center`}>
							<Link to={language.includes("pt") ? "/pt" : "/"}>
								<WannaLogo hasText={true} />
							</Link>
						</div>
						<div className={`${styles['navbar__group']} flex justify-end align-center`}>
							<ul className={`${styles.menu}`}>
								{fields.linkGroup.map(link => (
									<li key={link.label} className={styles['menu__item']}>
										<Link to={link.link}>{link.label}</Link>
									</li>
								))}
								<li className={`${styles['menu__item']} ${styles.languages__mobile} mobile-only`}>
									<a href="#" onClick={(e) => {e.preventDefault(); handleLanguageChange('en')}}>EN</a> <span>|</span> <a href="#" onClick={(e) => {e.preventDefault(); handleLanguageChange('pt')}}>PT</a>
								</li>
								<li className={styles.mobile__items}>
									<ul>
										<li><a href="https://www.instagram.com/wanna.brasil" target="_blank"><InstagramIcon /></a></li>
										{/*<li><a href="https://www.facebook.com/WannaBrasil" target="_blank"><FacebookIcon /></a></li>*/}
										<li><a href="https://twitter.com/wanna_brasil" target="_blank"><TwitterIcon /></a></li>
										<li><a href="https://www.youtube.com/channel/UCLkfp7tTZBta3dG5Qt0EUKQ" target="_blank"><YoutubeIcon /></a></li>
									</ul>

									<ul>
										<li><a href="#"><img src={AppStoreImage} alt="Download available in Apple Store" /></a></li>
										<li><a href="#"><img src={AndroidStoreImage} alt="Download available in Android Store" /></a></li>
									</ul>
								</li>
							</ul>

							<Button
								href="#"
								color="yellow"
								className={styles.button}
								label={language === "en" ? "Download App" : "Baixar App"}
								onClick={e => {
	                e.preventDefault()
	                !downloadModalIsVisible ? toggleDownloadModalIsVisible(true) : toggleDownloadModalIsVisible(false)
	              }}
							/>

							<div className={`${styles.dropdown} ${dropdownOpen && styles.dropdownActive}`}>
								<Globe className="desktop-only" onClick={() => setDropdownOpen(!dropdownOpen)} />
								<DownloadIcon className="mobile-only"
									onClick={e => {
										e.preventDefault()
										!downloadModalIsVisible ? toggleDownloadModalIsVisible(true) : toggleDownloadModalIsVisible(false)
									}}
								/>

								<ul className={styles['dropdown__menu']}>
									<li className={styles['dropdown__menu__item']}>
										<Link to="#" onClick={() => handleLanguageChange('pt')}>Português</Link>
									</li>
									{/* <li className={styles['dropdown__menu__item']}>
										<Link to="#" onClick={() => handleLanguageChange('es')}>Español</Link>
									</li> */}
									<li className={styles['dropdown__menu__item']}>
										<Link to="#" onClick={() => handleLanguageChange('en')}>English</Link>
									</li>
								</ul>
							</div>

							<div className={styles.burger}>
								<button type="button" onClick={(e) => setMobileOpen(!mobileOpen)}>
									<span></span>
									<span></span>
									<span></span>
								</button>
							</div>
						</div>
					</nav>
				)
			}}
		</Context.Consumer>
	)
}

export default Navbar
