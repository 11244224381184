// extracted by mini-css-extract-plugin
export var download = "style-module--download--L-yZm";
export var card__wrapper = "style-module--card__wrapper--1JNK5";
export var card = "style-module--card--3T8Pd";
export var buttons__group = "style-module--buttons__group--1yFJP";
export var mockup = "style-module--mockup--3hDqg";
export var jump = "style-module--jump--3yJ99";
export var glowYellow = "style-module--glowYellow--4GYEF";
export var glowPurple = "style-module--glowPurple--JKQJ-";
export var fadedStar = "style-module--fadedStar--3nD9U";
export var isHome = "style-module--is-home--1QKgN";
export var notHome = "style-module--not-home--1w77w";