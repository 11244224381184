import styled from "styled-components"
import { Tokens } from "../Theme/Tokens"


export const Modal = styled.div`
  position: fixed;
  width: 95vw;
  max-width: 600px;
  max-height: 95vh;
  overflow: auto;
  z-index: ${Tokens.z.modal};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${Tokens.color.white};
  align-items: center;
  text-align: center;
  padding: calc(${Tokens.rhythm} * 2) 0 ${Tokens.rhythm};
  -webkit-overflow-scrolling: touch;

  & > p {
    font-weight: ${Tokens.font.weight.bold};
  }
`

export const ModalBackdrop = styled.div`
  position: fixed;
  z-index: ${Tokens.z.language};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Tokens.color.purple};
  opacity: 0.4;
`

export const MenuHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: top;
  width: 100%;
  padding-bottom: ${Tokens.rhythm};
`

export const HeaderLogo = styled.div`
  width: 120px;
`

export const Language = styled.div`
  padding-bottom: calc(${Tokens.rhythm} * 2);
`

export const Close = styled.div`
  position: absolute;
  top: ${Tokens.rhythm};
  right: ${Tokens.rhythm};
  font-size: ${Tokens.font.size.ultra};
  font-weight: ${Tokens.font.weight.normal};
  line-height: 1;
  cursor: pointer;
`

export const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  & > * {
    color: ${Tokens.color.default};
    font-size: ${Tokens.font.size.base};
    font-weight: ${Tokens.font.weight.bold};
    text-decoration: none;
    padding-bottom: ${Tokens.rhythm};
  }
`
