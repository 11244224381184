import React from 'react'
import { Link } from 'gatsby'
import * as styles from './style.module.scss'
import { Context } from "../Context"


const Download = ({homepage, title, content, buttons, mockup}) => {


	return (
		<Context.Consumer>
      {({
        language
      }) => {
				return (
					<section className={styles.download}>
						<div className={`${styles.card} flex justify-center align-center card card__default card--yellow`}>
							<h1>{title}</h1>

							{content ?? <p>{content}</p>}

							{
								buttons &&
								<div className={`${styles.buttons} flex justify-center align-center`}>
									<Link to={buttons?.appleLink}>
										<img src={buttons?.appleImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="Download from Apple Store" />
									</Link>
									<Link to={buttons?.androidLink}>
										<img src={buttons?.androidImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="Download from Google Play Store" />
									</Link>
								</div>
							}

							<div className={styles.mockupHolder}></div>
							{ mockup && <img className={styles.mockup} src={mockup?.src} /> }
						</div>
					</section>
				)
			}}
		</Context.Consumer>
	)
}

export default Download
