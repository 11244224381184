import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Context } from "../Context"
import {
  Footer,
	Container,
  Navbar,
  LanguageModal,
  DownloadModal,
  VideoModal,
  StoriesModal
} from "../../components"
import { Tokens } from "../../components/Theme/Tokens"
import useOnlyRenderIfBrowser from "../../hooks/useOnlyRenderIfBrowser"
import LanguageToLowercase from "../../hooks/languageToLowercase"

import "../../styles/main.scss"

const Layout = ({ children, hasScrolled }) => {

  const isBrowser = useOnlyRenderIfBrowser()

  let defaultLang =
    typeof window !== "undefined" && window.localStorage.getItem("wannaLang")

  if (
    !defaultLang &&
    typeof window !== "undefined" &&
    window.location.pathname.includes("pt")
  ) {
    defaultLang = "pt"
  }

  const [language, toggleLanguage] = useState(defaultLang ? defaultLang : "en")
  const [languageModalIsVisible, toggleLanguageModalIsVisible] = useState(false)
  const [downloadModalIsVisible, toggleDownloadModalIsVisible] = useState(false)
  const [videoModalIsVisible, toggleVideoModalIsVisible] = useState(false)
  const [storiesModalIsVisible, toggleStoriesModalIsVisible] = useState(false)

  let wannaLang = typeof window !== "undefined" && window.localStorage.getItem("wannaLang")
  let browserLang = typeof window !== "undefined" && window.navigator.language
  let lowerCaseLang = LanguageToLowercase(browserLang)
  if (!wannaLang) {
    if (lowerCaseLang === "pt-br" || lowerCaseLang === "pt-pt" || lowerCaseLang === "pt") {
      toggleLanguage("pt")
      typeof window !== "undefined" && window.localStorage.setItem("wannaLang", "pt")
    }
  }

  useEffect(() => {
    const langList = ["pt"]

    const path =
      typeof window !== "undefined" &&
      window.location.pathname.split("/").filter(item => item.length)
    // .filter(item => item.length && !langList.includes(item))

    if (language && language !== "en" && !path.includes(language)) {
      navigate(`/${language ? language + "/" : ""}`)
    }

    if (
      language &&
      language === "en" &&
      path.some(component => langList.includes(component))
    ) {
      navigate("/")
    }
  }, [language])

  const context = {
    language,
    toggleLanguage,
    languageModalIsVisible,
    toggleLanguageModalIsVisible,
    downloadModalIsVisible,
    toggleDownloadModalIsVisible,
    videoModalIsVisible,
    toggleVideoModalIsVisible,
    storiesModalIsVisible,
    toggleStoriesModalIsVisible
  }

  const updateLanguage = lang => {
    toggleLanguage(lang)
    toggleLanguageModalIsVisible(false)
    toggleDownloadModalIsVisible(false)

    if (typeof window !== "undefined") {
      window.localStorage.setItem("wannaLang", lang)
    }
  }

  return (
    isBrowser && (
      <Context.Provider value={context}>
				<Navbar
          hasScrolled={hasScrolled}
          onUpdate={updateLanguage}
          language={language}
        />

        <main>{children}</main>

        <Footer
          lang={language}
        />

        <LanguageModal
          isVisible={languageModalIsVisible}
          onUpdate={updateLanguage}
          onClose={toggleLanguageModalIsVisible}
          currentLanguage={language}
        />

        <DownloadModal
          isVisible={downloadModalIsVisible}
        />

        <VideoModal
          isVisible={videoModalIsVisible}
        />

        <StoriesModal
          isVisible={storiesModalIsVisible}
        />
      </Context.Provider>
    )
  )
}

export default Layout
