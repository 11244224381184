import React from 'react'
import styled from 'styled-components'

const GlowComponent = styled.div`
	display: block;
	width: ${props => props.width ?? "400px" };
	height: ${props => props.height ?? "400px"};
	background: ${props => props.color ?? "var(--color-secondary)"};
	position: ${props => props.position?.type ?? "absolute"};
	top: ${props => props.position?.top ?? "initial" };
	right: ${props => props.position?.right ?? "initial"};
	bottom: ${props => props.position?.bottom ?? "initial"};
	left: ${props => props.position?.left ?? "initial"};
	z-index: ${props => props.zIndex ?? 0};
	border-radius: 50%;
	filter: blur(${props => props.blur ?? "100px"});
	transform: ${props => props.transform ?? "none"};
	transition all 1s cubic-bezier(0.2, 1, 1, 1);
`

/**
 * Render a faded/blurred ball shaped element
 * @param {string} color
 * @param {string} width
 * @param {string} height
 * @param {object|string} position
 * @param {string} transform
 * @param {number} zIndex
 * @param {string} blur
 * @returns 
 */
const Glow = ({ref, className, color, width, height, position, transform, zIndex, blur, dataProps}) => {
	return (
		<GlowComponent
			ref={ref}
			width={width}
			height={height}
			color={color}
			position={position}
			transform={transform}
			blur={blur}
			className={className}
			zIndex={zIndex}
			{...dataProps}
		/>
	)
}

export default Glow