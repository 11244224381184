// extracted by mini-css-extract-plugin
export var navbar = "style-module--navbar--3C2Sv";
export var navbar__group = "style-module--navbar__group--1LNHf";
export var menu = "style-module--menu--2KhQl";
export var menu__item = "style-module--menu__item--3YFDs";
export var languages__mobile = "style-module--languages__mobile--33V3b";
export var mobile__items = "style-module--mobile__items--JXeGE";
export var dropdown = "style-module--dropdown--BRXh6";
export var dropdown__menu = "style-module--dropdown__menu--1TQgl";
export var dropdown__menu__item = "style-module--dropdown__menu__item--2yFeQ";
export var dropdownActive = "style-module--dropdown--active--2XkRR";
export var navbarPurple = "style-module--navbar--purple--13TCG";
export var burger = "style-module--burger--gKfl7";
export var navbar__mobileActive = "style-module--navbar__mobile--active--ATnuZ";
export var button = "style-module--button--ArLdL";