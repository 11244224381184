import React from "react"
import { navigate } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import { Share, ArrowRight } from '/src/assets/vectors'

import * as styles from './style.module.scss'

/**
 * Render a card containing a image, content, excerpt, title and author
 * @param {Object | excerpt, author, links} shouldHave
 * @returns 
 */
const PostCard = ({ className, key, post, shouldHave, pageContext }) => {
  const postLink = `/${pageContext.lang === "en_us" ? 'en' : 'pt'}/blog/${post.categories[0].slug}/${post.slug}/`
  const catLink = `/${pageContext.lang === "en_us" ? 'en' : 'pt'}/blog/${post.categories[0].slug}/`

  return (
		<article key={key ?? Math.random()} className={`${styles.article} ${className}`}>
			<div className={styles.article__head}>
				<h1>{post.blog.blogTitle}</h1>
				
				<div className={styles.author} onClick={() => navigate(post.author.link)}>
					{
						shouldHave?.author && <>
							<div className={styles.author__image}>
								<GatsbyImage
									image={post.author.image.localFile.childImageSharp.gatsbyImageData}
									alt={post.author.name}
								/>
							</div>
							<div className={styles.author__content}>
								<p className={styles.author__name}>{post.author.name}</p>
								<p className={styles.date}>{post.blog.readingTime} {(pageContext.lang === "en_us") ? "read" : "leitura"}</p>
							</div>
						</>
					}
					
					<div className={styles.categories}>
						<Link
							className="pill"
							key={post.categories[0].databaseId}
							to={catLink}
						>
							{post.categories[0].name}
						</Link>
					</div>
				</div>

				<div className={styles.featuredImage}>
					{post.blog.blogImage && (
						<Link to={postLink}>
							<GatsbyImage
								image={post.blog.blogImage.localFile.childImageSharp.gatsbyImageData}
								className={styles.image}
								alt={post.blog.blogTitle} />
						</Link>
					)}
				</div>
			</div>

			<div className={styles.article__body}>
				{
					shouldHave?.excerpt &&
					<p className={styles.excerpt}>{post.blog.excerpt}...</p>	
				}

				{
					shouldHave?.links &&
					<div className={styles.link}>
						<Share className={styles.share}/>

						<Link to={postLink}>
							<p>{pageContext.lang === "pt_br" ? "leia mais" : "read more"}<ArrowRight /></p>
						</Link>
					</div>
				}
			</div>
		</article>
  )
}


export default PostCard
