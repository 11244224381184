import React from "react"
import Cookies from "js-cookie"
import { navigate } from "gatsby"

import { Button } from '../'
import serializeFormData from './serialize'

import * as styles from './style.module.scss'

const Contact = ({ data, lang, className, thanksMessage }) => {
	const [ formSuccess, setFormSuccess ] = React.useState(false)

  const thankULink = lang.includes('pt') ? '/pt/obrigado/' : '/thanks/'
  const isBrowser = typeof window !== 'undefined'
  const pageUri = isBrowser ? window.location.href : null
  const pageName = isBrowser ? document.title : null
  const url = 'https://api.hsforms.com/submissions/v3/integration/submit/8390676/f3e930bb-2e84-4978-98bd-6208e88250b7'
  const hutk = isBrowser ? Cookies.get('hubspotutk') : null;

	const handleSubmit = async (e) => {
		e.preventDefault();
		const fields = serializeFormData(e.target)
		const requestOptions = {
			method: 'POST',
			headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
			body: JSON.stringify({
				submittedAt: Date.now(),
				fields,
				context: {
					hutk,
					pageUri,
					pageName
				}
			})
		}
		await fetch(url, requestOptions)
		.then(resp => resp.json())
    .then(resp => {
			if ( resp.status === 'error' )
				return alert(resp.errors[0].message)

			setFormSuccess(true)
    })
	}

  return (
		<div className={`${styles.contact__wrapper} ${className ?? ''}`}>
			<form
				data-form-id='a3d8ff5f-c2fa-44e8-b909-23b8846d5101'
				data-portal-id='8390676'
				onSubmit={handleSubmit}
				className={`${styles.form} ${formSuccess ? '' : styles.active}`}
			>
				<div className="flex flex-wrap">
					<input type="text" className="input flex-50" name="firstname" placeholder={data.formLabels.firstName} required/>
					<input type="text" className="input flex-50" name="lastname" placeholder={data.formLabels.lastName} />
					<input type="email" className="input flex-100" name="email" placeholder={data.formLabels.email} required/>
					<input type="text" className="input flex-50" name="phone" placeholder={data.formLabels.phone} />
					<input type="text" className="input flex-50" name="user_country" placeholder={data.formLabels.country} required/>
					<select className="input flex-100" name="subject" id="subject" required>
						<option value="" selected disabled>{lang === 'pt_br' ? "Selecione uma opção" : "Select an option"}</option>
						<option value="my-account">{lang === 'pt_br' ? "Minha conta" : "My account"}</option>
						<option value="deposits">{lang === 'pt_br' ? "Depósitos" : "Deposits"}</option>
						<option value="withdraw">{lang === 'pt_br' ? "Saque" : "Withdraw"}</option>
						<option value="suggestion">{lang === 'pt_br' ? "Sugestão" : "Suggestion"}</option>
						<option value="create-your-bet">{lang === 'pt_br' ? "Crie sua aposta" : "Create your bet"}</option>
					</select>
					<textarea name="message" className="textarea flex-100" placeholder={data.formLabels.message} required></textarea>
				</div>

				<Button
					type="submit"
					color="yellow"
					className={styles.button}
					label={data.formLabels.button}
				/>
				<div style={{clear: "both"}}></div>
			</form>

			<div className={`${styles.success} ${formSuccess ? styles.active : ''}`}>
				<h1>{thanksMessage.thanksHeading}</h1>
				<div dangerouslySetInnerHTML={{__html: thanksMessage.thanksCopy}}></div>
			</div>
		</div>
  )
}

export default Contact
