import React, { useState, useRef } from 'react'
import Slider from 'react-slick'
import { Container, FadedStar, Glow, Button } from '../'
import { Link } from "gatsby"
import { ArrowRight, Cross } from '../../assets/vectors'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as styles from './style.module.scss'

import { useLocomotiveScroll } from 'react-locomotive-scroll'

const Stories = ({data, language, outerPage, slidesToShow, fullWidth, isWinnersPage}) => {
	const [ activeWinner, setActiveWinner ] = useState('')

	const { stories, storyPosts } = data
	const { scroll } = useLocomotiveScroll()

	const contentGroup = useRef()
	const slider = useRef()
	const section = useRef()

	const carouselSettings = {
		autoPlay: true,
		arrows: false,
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: slidesToShow ?? 3,
		slidesToScroll: slidesToShow ?? 3,
		responsive: [
			{
				breakpoint: 1470,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}

	const getStoryContent = (story) => {
		return story.node.winnersPostFields.winnersFields.find(
			variant => variant.languageValue.slug === language
		)
	}

	const getStoryImage = (story) => {
		return story.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData
		.images.sources[0].srcSet.split(',')[getStoryImage.length - 1]
		.split(' ')[getStoryImage.length - 1]
	}

	const addLineBreakOnStoryLocation = (location) => {
		const lastIndex = location.lastIndexOf(" ")

		return {
			city: location.substr(0, lastIndex),
			country: location.substr(lastIndex + 1)
		}
	}

	const openWinnerInfo = (winner) => {
		const elem = contentGroup.current.querySelector(`[data-name="${winner}"]`)
		elem.classList.add(styles.additionalStoryDataActive);
		section.current.classList.add(styles.isActive);

		if ( isWinnersPage ) {
			document.getElementsByTagName("html")[0].style.overflow = "hidden";
			document.getElementById('winnersStories').scrollIntoView(
				{behavior: "smooth"}
			);
			return;
		}

		let offset = 0;
		if ( typeof window !== 'undefined' ) {
			if ( window.innerWidth >= 1600 ) {
				offset = -300
			}

			if ( window.innerWidth < 1600 && window.innerWidth > 1380 ) {
				offset = -150
			}

			if ( window.innerWidth <= 1380 ) {
				offset = -100
			}
		}

		scroll.scrollTo('#stories', {
			offset
		})
		scroll.stop()
	}

	const closeWinnerInfo = () => {
		if ( !isWinnersPage ) {
			scroll.start();
		} else {
			document.getElementsByTagName("html")[0].style.overflow = "initial";
		}

		const elem = contentGroup.current.querySelector(`.${styles.additionalStoryDataActive}`)
		elem.classList.remove(styles.additionalStoryDataActive)
		section.current.classList.remove(styles.isActive);
	}

	return (<>
		{
			storyPosts && storyPosts.length > 0 &&
			<section id="stories" ref={section} className={`${styles.stories}`}>
				<Container customWidth={fullWidth && "100%"}>
					<Cross className={styles.close} onClick={closeWinnerInfo} />

					{
						outerPage &&
						<div className={`${styles.title} flex align-center`}>
							<h1>{stories.heading}</h1>
							<p>{stories.subheading}</p>
						</div>
					}
					
					<div className={styles.content} ref={contentGroup}>
						<div className={styles.sliderParent} style={{width: "100%"}} ref={slider}>
							<Slider {...carouselSettings} ref={slider}>
								{
									storyPosts.map((post) => {
										return (
											<div key={getStoryContent(post).name}>
												<article
													className={styles.slide}
													style={{
														backgroundImage: `url(${getStoryImage(post)})`,
													}}
												>
													<div className={styles.content__group}>
														<div className="flex align-bottom">
															<h1>
																{getStoryContent(post).name.split(" ")[0]} <br/>
																{getStoryContent(post).name.split(" ")[1]}
															</h1>
															<p>
																{getStoryContent(post).location}
															</p>
														</div>

														<p className={styles.headline}>{getStoryContent(post).excerpt.replace(/<\/?[^>]+(>|$)/g, "")}</p>

														<Button
															type="button"
															color="yellow"
															className={styles.button}
															label="Leia Mais"
															onClick={() => openWinnerInfo(getStoryContent(post).name)}
														/>
													</div>
												</article>
											</div>
										)
									})
								}
							</Slider>
						</div>

						{
							storyPosts.map((post, i) => {
								return (
									<div key={Math.random()} data-name={getStoryContent(post).name} className={`${styles.additionalStoryData} ${isWinnersPage ? styles.isWinnersPage : ''}`}>
										<article
											className={styles.slide}
											style={{
												backgroundImage: `url(${getStoryImage(post)})`,
											}}
										>
											<div className={styles.content__group}>
												<div className="flex align-bottom">
													<h1>
														{getStoryContent(post).name.split(" ")[0]} <br/>
														{getStoryContent(post).name.split(" ")[1]}
													</h1>
													<p>
														{getStoryContent(post).location}
													</p>
												</div>

												{
													getStoryContent(post).excerpt &&
													<p className={styles.headline}>{getStoryContent(post)?.excerpt?.replace(/<\/?[^>]+(>|$)/g, "")}</p>
												}
											</div>
										</article>
										<article
											className={styles.slide}
											style={
												getStoryContent(post).secondaryImage &&
												{ backgroundImage: `${getStoryContent(post).secondaryImage?.localFile.childImageSharp.gatsbyImageData.images.fallback.src}` }
											}
										>
											{
												getStoryContent(post).secondaryContent &&
												<div className={styles.content__group}>
													<p className={styles.headline}>{getStoryContent(post).secondaryContent.replace(/<\/?[^>]+(>|$)/g, "")}</p>
												</div>
											}
										</article>
										<article
											className={styles.slide}
											style={
												getStoryContent(post).thirdImage &&
												{ backgroundImage: `${getStoryContent(post).thirdImage?.localFile.childImageSharp.gatsbyImageData.images.fallback.src}` }
											}
										>
											{
												getStoryContent(post).thirdContent &&
												<div className={styles.content__group}>
													<p className={styles.headline}>{getStoryContent(post).thirdContent.replace(/<\/?[^>]+(>|$)/g, "")}</p>
												</div>
											}
										</article>
									</div>
								)
							})
						}
					</div>

					{
						outerPage &&
						<Link to="/pt/winners" className={styles.link__alternate}>{stories.label}<ArrowRight /></Link>
					}
				</Container>

			</section>
		}
		<div className={styles.backdrop} onClick={closeWinnerInfo}></div>
	</>)
}

export default Stories
