import styled from "styled-components"
import { Tokens } from "../Theme/Tokens"

export const Modal = styled.div`
  position: fixed;
  width: 95vw;
  max-width: 600px;
  max-height: 95vh;
  overflow: auto;
  z-index: ${Tokens.z.modal};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f5cc45;
  border-radius: 8px;
  align-items: center;
  text-align: center;
  padding: calc(${Tokens.rhythm} * 2) 0 ${Tokens.rhythm};
  -webkit-overflow-scrolling: touch;
`

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: top;
  width: 100%;
  padding-bottom: ${Tokens.rhythm};
  & > h3 {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 40px;
    color: #3E2794;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
  }
`

export const Buttons = styled.div`
  padding-bottom: calc(${Tokens.rhythm} * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  & > :first-child {
    margin-right: 15px;
  }
  & > a img {
    width: 117px;
    height: auto;
    transform: scale(1);
    transition: transform 150ms ease;
    &:hover {
      transform: scale(1.1);
    }
  }
`

export const Close = styled.div`
  position: absolute;
  top: 0;
  right: ${Tokens.rhythm};
  font-size: ${Tokens.font.size.ultra};
  font-weight: ${Tokens.font.weight.normal};
  line-height: 1;
  cursor: pointer;
`

export const ModalBackdrop = styled.div`
  position: fixed;
  z-index: ${Tokens.z.language};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: none;
  opacity: 0.4;
`
