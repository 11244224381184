import { useEffect, useState } from "react"


export default function LanguageToLowercase(browserLang) {
  const [loweredLang, setLoweredLang] = useState(null)

  useEffect(() => {
    let lang = browserLang.toLowerCase()
    setLoweredLang(lang)
  }, [])

  return loweredLang
}
