import React from 'react'

export const ArrowRight = ({className, onClick}) => {
	return (
		<svg className={className} onClick={onClick} width="12" height="12" viewBox="0 0 12 12" fill="none">
			<path d="M8.78068 5.33336L5.20468 1.75736L6.14735 0.814697L11.3327 6.00003L6.14735 11.1854L5.20468 10.2427L8.78068 6.6667H0.666016V5.33336H8.78068Z" fill="white"/>
		</svg>
	)
}

export const ArrowLeft = ({className, onClick}) => {
	return (
		<svg className={className} onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path d="M3.828 7.00017H16V9.00017H3.828L9.192 14.3642L7.778 15.7782L0 8.00017L7.778 0.222168L9.192 1.63617L3.828 7.00017Z" fill="#2F1152"/>
		</svg>
	)
}

export const ChevronDown = ({className, onClick}) => {
	return (
		<svg className={className} onClick={onClick} width="10" height="7" viewBox="0 0 10 7" fill="none">
			<path d="M4.99996 6.50002L0.756958 2.25702L2.17196 0.843018L4.99996 3.67202L7.82796 0.843018L9.24296 2.25702L4.99996 6.50002Z" fill="white"/>
		</svg>
	)
}