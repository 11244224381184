import styled from "styled-components"
import { Tokens } from "../Theme/Tokens"


export const Container = styled.div`
  padding: calc(${Tokens.rhythm} * 4) 0;
`

export const Image = styled.div`
  width: 521px;
  height: 640px;

  @media (min-width: ${Tokens.screen.medium}) {
    margin: 0px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 521px;

  @media (min-width: ${Tokens.screen.medium}) {
    margin: 0px 130px 0px 0px;
  }
`

export const Nav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  width: 521px;
  left: 0px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 35px;

  @media (min-width: ${Tokens.screen.medium}) {
  }
`

export const Heading = styled.div`
  font-family: ${Tokens.font.family.barlow};
  font-style: normal;
  font-size: 37px;
  line-height: 76px;
  text-transform: uppercase;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px;

  @media (min-width: ${Tokens.screen.medium}) {

  }
`

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  flex: none;
  order: 1;
  flex-grow: 1;
  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 32px;

  @media (min-width: ${Tokens.screen.medium}) {
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 12px;
    background-color: rgba(255, 255, 255, 0.08);
    flex: none;
    flex-grow: 0;
    margin: 0px 8px;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    text-transform: uppercase;
    box-shadow: none;
    border: none;
    border-radius: 100px;
    color: ${Tokens.color.white};
    cursor: pointer;
  }
`

export const Bullets = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px;
  & > :nth-child(1) {
    order: 0;
  }
  & > :nth-child(2) {
    order: 1;
  }
  & > :nth-child(3) {
    order: 2;
  }
`

export const Bullet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px 16px 0px;
  .excerpt {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0 0 16px 0;
  }
`

export const BulletHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 16px;
  h3 {
    font-family: ${Tokens.font.family.barlow};
    font-style: normal;
    font-size: 32px;
    line-height: 32px;
    display: flex;
    align-items: center;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 8px;
  }
  button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 12px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 100px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px;
    box-shadow: none;
    border: none;
    a {
      font-weight: 500;
      font-size: 11px;
      line-height: 20px;
      text-transform: uppercase;
      flex: none;
      order: 0;
      flex-grow: 0;
      color: ${Tokens.color.white};
      text-decoration: none;
    }
  }
`


export const PostLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${Tokens.color.white};
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
    text-decoration: underline;
  }
  svg {
    flex: none;
    order: 1;
    flex-grow: 0;
  }
`

export const Shape = styled.div`
  width: 495.1px;
  height: 930.42px;
  background: linear-gradient(208.89deg, #9816F6 1.69%, #3E2794 63.59%), #C4C4C4;
  border-radius: 96px;
  transform: rotate(25.99deg);
  position: absolute;
  left: calc(50% - 495.1px/2 - 577.74px);
  top: 2934px;
  z-index: ${Tokens.z.behind};
`
