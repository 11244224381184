import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Favicon from '../../assets/img/wanna_logo.ico'

function Seo({ description, lang, meta, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const ga = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', 'G-3ZK3J1RL04');`

  return (
    <Helmet
      htmlAttributes={{
        lang,
        translate: "no",
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
				{
					name: "icon",
					href: Favicon
				},
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: image,
        },
      ].concat(meta)}
    >
			<link rel="shortcut icon" href={Favicon} />
      <meta property="og:title" content="The future of sports gambling" />
      <meta property="og:image" content="http://wp.stage.wanna.com/wp-content/uploads/2022/08/Wanna-Web-Thumb@3x.png" />
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-3ZK3J1RL04"></script>
      <script dangerouslySetInnerHTML={{__html: ga}}> </script>
		</Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image:
    "https://wanna.com/static/2988434122a25ed82c62f28369380a8b/00405/hero-en.png",
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default Seo
