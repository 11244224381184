import React from 'react'

export const Android = ({className, onClick}) => {
	return (
		<svg className={className} onClick={onClick} width="571" height="325" viewBox="0 0 571 325" fill="none">
			<path d="M417.126 242.869C412.406 242.869 407.792 241.454 403.868 238.805C399.943 236.156 396.884 232.39 395.078 227.984C393.272 223.578 392.799 218.73 393.72 214.053C394.641 209.375 396.914 205.079 400.251 201.707C403.589 198.335 407.841 196.038 412.47 195.108C417.099 194.178 421.898 194.655 426.258 196.48C430.619 198.305 434.346 201.396 436.968 205.361C439.59 209.326 440.99 213.988 440.99 218.757C440.99 225.152 438.476 231.284 434 235.806C429.525 240.328 423.455 242.869 417.126 242.869ZM154.371 242.869C149.651 242.869 145.037 241.454 141.113 238.805C137.189 236.156 134.13 232.39 132.324 227.984C130.517 223.578 130.045 218.73 130.966 214.053C131.887 209.375 134.159 205.079 137.497 201.707C140.834 198.335 145.086 196.038 149.716 195.108C154.345 194.178 159.143 194.655 163.504 196.48C167.864 198.305 171.591 201.396 174.214 205.361C176.836 209.326 178.235 213.988 178.235 218.757C178.235 225.152 175.721 231.284 171.246 235.806C166.77 240.328 160.7 242.869 154.371 242.869ZM425.578 98.1967L473.306 15.0607C473.95 13.9223 474.365 12.6666 474.527 11.3657C474.69 10.0648 474.596 8.74432 474.252 7.4801C473.907 6.21589 473.319 5.03287 472.521 3.99899C471.723 2.96511 470.731 2.10075 469.601 1.45557C468.471 0.81038 467.226 0.39708 465.938 0.23941C464.65 0.0817392 463.343 0.182806 462.094 0.536802C460.844 0.890797 459.676 1.49075 458.657 2.30219C457.637 3.11363 456.787 4.12057 456.154 5.26517L407.928 89.4059C369.626 71.7212 327.984 62.638 285.873 62.7823C243.712 62.678 202.015 71.6692 163.569 89.1547L115.343 5.014C114.051 2.72939 111.916 1.05489 109.404 0.356727C106.893 -0.34144 104.211 -0.0064609 101.943 1.2884C99.6759 2.58327 98.0084 4.73264 97.3052 7.26646C96.6021 9.80027 96.9206 12.5123 98.1911 14.8095L145.671 97.9456C104.618 120.568 69.7976 153.178 44.3621 192.824C18.9265 232.47 3.67905 277.9 0 325H571C567.434 277.933 552.257 232.518 526.855 192.9C501.453 153.282 466.634 120.724 425.578 98.1967Z" fill="#3DDC84"/>
			<path d="M417.126 242.869C412.406 242.869 407.792 241.454 403.868 238.805C399.943 236.156 396.884 232.39 395.078 227.984C393.272 223.578 392.799 218.73 393.72 214.053C394.641 209.375 396.914 205.079 400.251 201.707C403.589 198.335 407.841 196.038 412.47 195.108C417.099 194.178 421.898 194.655 426.258 196.48C430.619 198.305 434.346 201.396 436.968 205.361C439.59 209.326 440.99 213.988 440.99 218.757C440.99 225.152 438.476 231.284 434 235.806C429.525 240.328 423.455 242.869 417.126 242.869ZM154.371 242.869C149.651 242.869 145.037 241.454 141.113 238.805C137.189 236.156 134.13 232.39 132.324 227.984C130.517 223.578 130.045 218.73 130.966 214.053C131.887 209.375 134.159 205.079 137.497 201.707C140.834 198.335 145.086 196.038 149.716 195.108C154.345 194.178 159.143 194.655 163.504 196.48C167.864 198.305 171.591 201.396 174.214 205.361C176.836 209.326 178.235 213.988 178.235 218.757C178.235 225.152 175.721 231.284 171.246 235.806C166.77 240.328 160.7 242.869 154.371 242.869ZM425.578 98.1967L473.306 15.0607C473.95 13.9223 474.365 12.6666 474.527 11.3657C474.69 10.0648 474.596 8.74432 474.252 7.4801C473.907 6.21589 473.319 5.03287 472.521 3.99899C471.723 2.96511 470.731 2.10075 469.601 1.45557C468.471 0.81038 467.226 0.39708 465.938 0.23941C464.65 0.0817392 463.343 0.182806 462.094 0.536802C460.844 0.890797 459.676 1.49075 458.657 2.30219C457.637 3.11363 456.787 4.12057 456.154 5.26517L407.928 89.4059C369.626 71.7212 327.984 62.638 285.873 62.7823C243.712 62.678 202.015 71.6692 163.569 89.1547L115.343 5.014C114.051 2.72939 111.916 1.05489 109.404 0.356727C106.893 -0.34144 104.211 -0.0064609 101.943 1.2884C99.6759 2.58327 98.0084 4.73264 97.3052 7.26646C96.6021 9.80027 96.9206 12.5123 98.1911 14.8095L145.671 97.9456C104.618 120.568 69.7976 153.178 44.3621 192.824C18.9265 232.47 3.67905 277.9 0 325H571C567.434 277.933 552.257 232.518 526.855 192.9C501.453 153.282 466.634 120.724 425.578 98.1967Z" fill="url(#paint0_diamond_3234_54178)" fillOpacity="0.2"/>
			<defs>
			<radialGradient id="paint0_diamond_3234_54178" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(410.5 205.5) rotate(136.289) scale(172.931 303.827)">
			<stop stopColor="#27770B"/>
			<stop offset="0.761423" stopOpacity="0"/>
			</radialGradient>
			</defs>
		</svg>
	)
}
