import React from "react"
import * as Styles from "./styles"


const LanguageModal = ({ isVisible, onUpdate, onClose, currentLanguage }) => {
  return isVisible ? (
    <>
      <Styles.Modal>
        <Styles.Arrow>
        </Styles.Arrow>
        <Styles.LinkGroup>
          <Styles.Link onClick={() => onUpdate("en")}>Português</Styles.Link>
          <Styles.Link onClick={() => onUpdate("pt")}>English</Styles.Link>
        </Styles.LinkGroup>
      </Styles.Modal>
      <Styles.ModalBackdrop
        onClick={e => {
          e.preventDefault()
          onClose(false)
        }}>
      </Styles.ModalBackdrop>
    </>
  ) : null
}


export default LanguageModal
