import React from 'react'
import { Context } from "../Context"
import { Button } from '../'
import { Link, graphql, useStaticQuery } from "gatsby"
import { WannaLogo, FacebookIcon, InstagramIcon, TelegramIcon, TwitterIcon, WhatsappIcon, YoutubeIcon } from '/src/assets/vectors'
import GamingCuracaoLogo from '/src/assets/img/gaming-curacao.png'
import serializeFormData from './serialize'
import Cookies from "js-cookie"
import { navigate } from "gatsby"
import * as styles from './style.module.scss'

const Footer = ({ lang, style, isHome }) => {
	const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "header-logo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      wp: wpPage(slug: { eq: "footer" }) {
        footerFields {
          footerFields {
            languageValue {
              slug
            }
            links1 {
              label
              link
            }
						links2 {
              label
              link
            }
						links3 {
              label
              whatsapp
							telegram
            }
						links4 {
              instagram
							facebook
							twitter
							youtube
            }
						license {
							link
							copy
						}
          }
        }
      }
    }
  `)
  

	const thankULink = lang.includes('pt') ? '/pt/obrigado/' : '/thanks/'
	const isBrowser = typeof window !== 'undefined'
	const pageUri = isBrowser ? window.location.href : null
	const pageName = isBrowser ? document.title : null
	const url = 'https://api.hsforms.com/submissions/v3/integration/submit/8390676/76a329ab-cbe7-4bbe-b734-08598bfa3cc4'
	const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
	const newsletterLabel = (window.location.href.indexOf("/pt") != -1) ? 'Assinar Newsletter' : 'Signup for Newsletter'

	const applyToNewsletter = async (e) => {
		e.preventDefault();
		const fields = serializeFormData(e.target)
		const requestOptions = {
			method: 'POST',
			headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
			body: JSON.stringify({
				submittedAt: Date.now(),
				fields,
				context: {
					hutk,
					pageUri,
					pageName
				}
			})
		}
		await fetch(url, requestOptions)
		.then(resp => resp.json())
    .then((resp) => {
    	resp.status === 'error' ? alert(resp.errors[0].message) : navigate(thankULink)
    })
	}

	return (
		<Context.Consumer>
      {({
        language
      }) => {
        const fields = data.wp.footerFields.footerFields.find(row =>
          row.languageValue.slug.includes(language)
        )
				return (
					<footer className={`${styles.footer} ${isHome ? styles.footerHome : ''}`} style={style}>
						<div className={styles.footer__row}>
							<div className="flex justify-start align-center">
								<ul className={styles.menu}>
									<li>
										<Link to={language.includes("pt") ? "/pt" : "/"}>
											<WannaLogo/>
										</Link>
									</li>

									{fields.links1.map(link => (
										<li key={link.label} className={styles['menu__item']}>
											<Link to={link.link}>{link.label}</Link>
										</li>
									))}
								</ul>

								<ul className={styles.support}>
									<li>{fields.links3.label}</li>
									<li>
										<Link to={fields.links3.whatsapp} target="_blank">
											<WhatsappIcon />
										</Link>
									</li>
									<li>
										<Link to={fields.links3.telegram} target="_blank">
											<TelegramIcon />
										</Link>
									</li>
								</ul>

								<ul className={`${styles.social} flex justify-center align-top`}>
									<li><a href={fields.links4.instagram} target="_blank"><InstagramIcon /></a></li>
									{/*<li><a href={fields.links4.facebook} target="_blank"><FacebookIcon /></a></li>*/}
									<li><a href={fields.links4.twitter} target="_blank"><TwitterIcon /></a></li>
									<li><a href={fields.links4.youtube} target="_blank"><YoutubeIcon /></a></li>
								</ul>
							</div>
						</div>

						<div className={styles.footer__row}>
							<div className="flex justify-start align-center">
								<div className={styles.newsletter}>


									<form className="flex"
									data-form-id='76a329ab-cbe7-4bbe-b734-08598bfa3cc4'
									data-portal-id='8390676'
									onSubmit={applyToNewsletter}
									>
										<input type="email" name="email" placeholder="email" required/>
										<Button
											type="submit"
											color="yellow"
											className={styles.button}
											label={newsletterLabel}
										/>
									</form>
									<ul className={`${styles.external__links} flex justify-start align-center`}>
										{fields.links2.map(link => (
											<li key={link.label}>
												<Link to={link.link}>{link.label}</Link>
											</li>
										))}
									</ul>

								</div>
								<div className={`${styles.disclaimer} flex`}>
									<iframe
											src={fields.license.link}
											width={150}
											height={50}
											style={{ border: "none" }}
											title="Valid Gaming License"
											referrerPolicy="strict-origin-when-cross-origin"
									></iframe>

									<p dangerouslySetInnerHTML={{ __html: fields.license.copy }} />
								</div>
							</div>
						</div>
					</footer>
				)
			}}
		</Context.Consumer>
	)
}

export default Footer
