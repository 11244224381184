// extracted by mini-css-extract-plugin
export var article = "style-module--article--3yhm9";
export var article__head = "style-module--article__head--26PIk";
export var author = "style-module--author--2ZSrc";
export var author__image = "style-module--author__image--hfAV1";
export var author__content = "style-module--author__content--dGNxd";
export var author__name = "style-module--author__name--DE5l0";
export var date = "style-module--date--27U7-";
export var featuredImage = "style-module--featured-image--1LFR5";
export var image = "style-module--image--2JWi7";
export var article__body = "style-module--article__body--ZgTRg";
export var excerpt = "style-module--excerpt--nxSGN";
export var link = "style-module--link--Bi9uB";
export var share = "style-module--share--2Ko5r";