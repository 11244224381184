import styled from "styled-components"
import { Tokens } from "../Theme/Tokens"


export const Modal = styled.div`
  position: fixed;
  width: 1228px;
  height: 659px;
  max-width: 1228px;
  overflow: auto;
  z-index: ${Tokens.z.modal};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  border-radius: 8px;
  iframe {
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    margin: 0px 0px;
  }
`

export const Close = styled.div`
  position: fixed;
  width: 26px;
  height: 26px;
  left: 1380px;
  top: 340px;
  z-index: ${Tokens.z.modal};
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.9995 10.172L22.8995 0.271973L25.7275 3.09997L15.8275 13L25.7275 22.9L22.8995 25.728L12.9995 15.828L3.09948 25.728L0.271484 22.9L10.1715 13L0.271484 3.09997L3.09948 0.271973L12.9995 10.172Z' fill='white'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const ModalBackdrop = styled.div`
  position: fixed;
  z-index: ${Tokens.z.language};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
`
