import React from "react"
import { navigate } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import { Share, ArrowRight } from '/src/assets/vectors'

import * as styles from './style.module.scss'

const Author = ({ className, post, pageContext }) => {
  const postLink = `/${pageContext.lang === "en_us" ? 'en' : 'pt'}/blog/${post.categories[0].slug}/${post.slug}/`
  const catLink = `/${pageContext.lang === "en_us" ? 'en' : 'pt'}/blog/${post.categories[0].slug}/`

  return (
		<div className={`${styles.author} ${className}`} onClick={() => navigate(post.author.link)}>
			<div className={styles.author__image}>
				<GatsbyImage
					image={post.author.image?.localFile.childImageSharp.gatsbyImageData}
					alt={post.author.name}
				/>
			</div>
			<div className={styles.author__content}>
				<p className={styles.author__name}>{post.author.name}</p>
				<p className={styles.date}>{post.date} • {post.blog.readingTime} {(pageContext.lang === "en_us") ? "read" : "leitura"}</p>
			</div>
			
			<div className={styles.categories}>
				<Link
					className="pill"
					key={post.categories[0].databaseId}
					to={catLink}
				>
					{post.categories[0].name}
				</Link>
			</div>
		</div>
  )
}

export default Author
