import styled from "styled-components"
import { Tokens } from "../Theme/Tokens"


export const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding: calc((${Tokens.rhythm} * 4) + 100px) 0 calc(${Tokens.rhythm} * 8);
`

export const Image = styled.div`
  width: 1047px;
  :hover {
    cursor: pointer;
  }
`
