import styled from "styled-components"
import { Tokens } from "../Theme/Tokens"


export const Modal = styled.div`
  position: fixed;
  left: 1270px;
  top: 65px;
  width: 125px;
  max-width: 600px;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  overflow: auto;
  z-index: ${Tokens.z.modal};
  text-align: center;
`

export const Arrow = styled.div`
  width: 125px;
  height: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  background-image: url("data:image/svg+xml,%3Csvg width='125' height='8' viewBox='0 0 125 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M109 8L98.1094 0.7396C97.4376 0.291734 96.5624 0.291734 95.8906 0.7396L85 8H109Z' fill='white'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px;
  background-color: ${Tokens.color.white};
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
  & > :nth-child(1) {
    order: 0;
    margin: 0px 0px 32px 0px;
  }
  & > :nth-child(2) {
    order: 1;
    margin: 0px;
  }
`

export const Link = styled.div`
  font-family: ${Tokens.font.family.barlow};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  flex: none;
  flex-grow: 0;
  background: -webkit-linear-gradient(157.36deg, #9816F6 -34.79%, #3E2794 73.85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const ModalBackdrop = styled.div`
  position: fixed;
  z-index: ${Tokens.z.language};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
`
