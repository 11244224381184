import { createContext } from "react"


export const Context = createContext({
  language: "en",
  toggleLanguage: () => {},
  languageModalIsVisible: false,
  toggleLanguageModalIsVisible: () => {},
  downloadModalIsVisible: false,
  toggleDownloadModalIsVisible: () => {},
  videoModalIsVisible: false,
  toggleVideoModalIsVisible: () => {},
  storiesModalIsVisible: false,
  toggleStoriesModalIsVisible: () => {},
})
