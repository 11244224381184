import React, { useState, useRef } from 'react'
import { Container, Glow } from '../'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Play, Cross } from '../../assets/vectors'
import { useLocomotiveScroll } from 'react-locomotive-scroll'
import * as styles from './style.module.scss'

const VideoFrame = ({data}) => {
	const [ videoActive, setVideoActive ] = useState(false)
	const iframe = useRef()

	const { scroll } = useLocomotiveScroll()

	const initVideo = () => {
		setVideoActive(true)

		let offset = 0;
		if ( typeof window !== 'undefined' ) {
			if ( window.innerWidth >= 1600 ) {
				offset = -300
			}

			if ( window.innerWidth < 1600 && window.innerWidth > 1380 ) {
				offset = -150
			}

			if ( window.innerWidth <= 1380 ) {
				offset = -100
			}
		}

		scroll.scrollTo('#video', {
			offset
		})
		scroll.stop()
	}

	const stopVideo = () => {
		iframe.current.setAttribute('src', data.bottom.videoLink)
		setVideoActive(false)
		scroll.start()
	}

	return (<>
		<section id="video" className={`${styles.video} ${videoActive ? styles.videoActive : ''}`}>
			<Cross className={styles.close} onClick={stopVideo} />

			<Container>
				<div className={`flex align-center `}>
					<div className={styles.frame} onClick={initVideo}>
						{/* <Glow
							color="var(--color-yellow)"
							width="200px"
							height="200px"
							position={{
								type: "absolute",
								top: 0,
								left: 0
							}}
							className={styles.glowYellow}
						/> */}

						<GatsbyImage
							image={data.bottom.videoThumbnail.localFile.childImageSharp.gatsbyImageData}
							alt="Betting Redefined"
							eager="true"
						/>

						<iframe ref={iframe} className={styles.iframe} width="560" height="315" src={`${data.bottom.videoLink}${videoActive ? '?autoplay=1' : ''}`} title={data.bottom.videoTitle} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

						<span className={styles.play}>
							<Play/>
						</span>

						{/* <Glow
							color="var(--color-primary)"
							width="300px"
							height="300px"
							blur="100px"
							position={{
								bottom: "-125px",
								right: "-80px"
							}}
							zIndex="0"
							className={styles.glowPurple}
						/> */}
					</div>
					<div className={styles.content} data-scroll data-scroll-speed="-1" data-scroll-direction="horizontal">
						<div>
							<h1>{data.bottom.heading}</h1>
							<p>{data.bottom.subheading}</p>
						</div>
					</div>
				</div>
			</Container>
		</section>
		<div className={styles.backdrop} onClick={stopVideo}></div>
	</>)
}

export default VideoFrame