// extracted by mini-css-extract-plugin
export var blog = "style-module--blog--37v97";
export var image = "style-module--image--1jaEB";
export var content = "style-module--content--b8HNx";
export var title = "style-module--title--2ySQs";
export var posts__mobile = "style-module--posts__mobile--K5cpB";
export var posts = "style-module--posts--jvqWb";
export var post = "style-module--post--1lIhV";
export var post__mobile = "style-module--post__mobile--BejrF";
export var image__wrapper = "style-module--image__wrapper--jY4xN";
export var image__mobile = "style-module--image__mobile--2puuE";
export var fadedStar = "style-module--fadedStar--I73lH";
export var roundedBox = "style-module--roundedBox--2drh5";
export var blogActive = "style-module--blog--active--7XWUe";