import React from 'react'
import { Container, FadedStar, Glow } from '../'
import { Link } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './style.module.scss'

const DownloadApp = ({data, notHome}) => {
	return (
		<section
			className={`
				${styles.download}
				${notHome ? styles.notHome : styles.isHome}
			`}
			// style={notHome ? {height: "auto"} : {height: "1350px"}}
		>
			<Container customWidth={notHome ? "100%" : ""}>
				<div data-scroll data-scroll-speed="-1" className={`${styles.card__wrapper} flex justify-center align-center`}>
					{/* <Glow
						className={styles.glowYellow}
						color="var(--color-yellow)"
						width="150px"
						height="150px"
						blur="80px"
						position={{
							top: "30px",
							left: "200px"
						}}
					/> */}

					<div className={styles.card}>
						<h1>{data.heading}</h1>
						<p>{data.subheading}</p>

						<div className={styles.buttons__group}>
							<Link to={data.buttons.appleLink}>
								<GatsbyImage image={data.buttons.appleImage?.localFile.childImageSharp.gatsbyImageData} />
							</Link>
							<Link to={data.buttons.androidLink}>
								<GatsbyImage image={data.buttons.androidImage?.localFile.childImageSharp.gatsbyImageData} />
							</Link>
						</div>

						<GatsbyImage
							image={data.mockup?.localFile.childImageSharp.gatsbyImageData}
							className={styles.mockup}
						/>
					</div>

					{/* <Glow
						className={styles.glowPurple}
						color="var(--color-primary)"
						width="300px"
						height="300px"
						blur="100px"
						position={{
							bottom: "-140px",
							right: "140px"
						}}
					/> */}
				</div>
			</Container>

			{
				!notHome &&
				<div data-scroll data-scroll-speed="3" data-scroll-direction="horizontal">
					<FadedStar
						className={styles.fadedStar}
						position={{
							right: "-33%",
							top: "-500px"
						}}
					/>
				</div>
			}
			
		</section>
	)
}

export default DownloadApp