import React, { useEffect, useState, useRef } from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Context } from "../Context"
import { Container, FadedStar, Glow, RoundedBox } from '../'
import { useLocomotiveScroll } from 'react-locomotive-scroll'

import { PurpleGlow } from '../../assets/vectors'

import * as styles from './style.module.scss'

const Hero = ({data, content}) => {
	const [ pageIsReady, setPageIsReady ] = useState(false)

	useEffect(() => {
		setPageIsReady(true);
	})

	return (
		<section className={`${styles.hero} ${pageIsReady ? styles.heroActive : ''}`}>
			<RoundedBox
				className={styles.roundedBox}
				transform="rotate(26deg)"
				width="916px"
				height="996px"
				position={{
					top: "-180px",
					left: "-1130px"
				}}
			/>

			<Container>
				<div className="flex">
					<div className={styles.hero__column}>
						<div className={styles.content__group}>
							<div data-scroll data-scroll-speed="1">
								<h1>{data.top.heading}</h1>
								<h2>{data.top.subheading}</h2>

								<div className={styles.image__containerMobile}>
									<GatsbyImage
										image={data.top.mockupImage.localFile.childImageSharp.gatsbyImageData}
										alt="Sports Betting Redefined"
									/>
								</div>

								<p>{data.top.paragraph1}</p>
								<p>{data.top.paragraph2}</p>
							</div>
						</div>
					</div>
					<div className={styles.hero__column}>
						{/* <Glow
							className={styles.glowYellow}
							color="var(--color-yellow)"
							width="200px"
							height="200px"
							position={{
								top: 0,
								left: 0
							}}
						/> */}

						<div className={styles.image__container}>
							<GatsbyImage
								image={data.top.mockupImage.localFile.childImageSharp.gatsbyImageData}
								alt="Sports Betting Redefined"
							/>
						</div>

						{/* <Glow
							className={styles.glowPurple}
							color="var(--color-secondary)"
							width="300px"
							height="300px"
							position={{
								bottom: "30px",
								right: 0
							}}
						/> */}
						{/* <PurpleGlow className={styles.glowPurple}/> */}
					</div>
				</div>
			</Container>

			<FadedStar
				className={styles.fadedStar}
				position={{
					right: "-33%",
					top: "-75px"
				}}
			/>
		</section>
	)
}

export default Hero