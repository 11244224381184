import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Context } from "../Context"
import * as Styles from "./styles"


const DownloadModal = ({ isVisible }) => {
  const data = useStaticQuery(graphql`
    {
      downloadContent: wpPage(slug: { eq: "home" }) {
    		homePageFields {
    			sectionDownload {
    				languageValue {
    					slug
    					name
    				}
            heading
    				buttons {
    					appleLink
    					appleImage {
    						localFile {
    							childImageSharp {
    								gatsbyImageData(
    									quality: 100
    									placeholder: NONE
    									layout: FULL_WIDTH
    								)
    							}
    						}
    					}
    					androidLink
    					androidImage {
    						localFile {
    							childImageSharp {
    								gatsbyImageData(
    									quality: 100
    									placeholder: NONE
    									layout: FULL_WIDTH
    								)
    							}
    						}
    					}
    				}
    			}
    		}
    	}
    }
  `)

  const { sectionDownload } = data.downloadContent.homePageFields

  return (
    <Context.Consumer>
      {({ language, toggleDownloadModalIsVisible }) => {
        const fields = sectionDownload.find(row =>
          row.languageValue.slug.includes(language)
        )
        const buttons = fields.buttons
        return isVisible ? (
          <>
            <Styles.Modal>
              <Styles.ModalHeader>
                <h3>{fields?.heading}</h3>
              </Styles.ModalHeader>
              <Styles.Buttons>
                <Link to={buttons?.appleLink}>
                  <img src={buttons?.appleImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="Apple Store" />
                </Link>
                <Link to={buttons?.androidLink}>
                  <img src={buttons?.androidImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="Google Play Store" />
                </Link>
              </Styles.Buttons>
              <Styles.Close onClick={e => {
                e.preventDefault()
                toggleDownloadModalIsVisible(false)
              }}>
                &times;
              </Styles.Close>
            </Styles.Modal>
            <Styles.ModalBackdrop
              onClick={e => {
                e.preventDefault()
                toggleDownloadModalIsVisible(false)
              }}
            ></Styles.ModalBackdrop>
          </>
        ) : null
      }}
    </Context.Consumer>
  )
}
export default DownloadModal
