// extracted by mini-css-extract-plugin
export var video = "style-module--video--oiyTo";
export var frame = "style-module--frame--3rfli";
export var iframe = "style-module--iframe--2Od5z";
export var play = "style-module--play--PAH5I";
export var content = "style-module--content--1ZoWb";
export var fadedStar = "style-module--fadedStar--2jKvJ";
export var glowYellow = "style-module--glowYellow--1_Sdz";
export var glowPurple = "style-module--glowPurple--32ahC";
export var close = "style-module--close--116mX";
export var backdrop = "style-module--backdrop--2-h1u";
export var videoActive = "style-module--video--active--1LeQg";