import styled from "styled-components"
import { Tokens } from "../Theme/Tokens"


export const Container = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 300px 0px;
`

export const BlockCopy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 408px;
  left: 183px;
  top: 2535px;
  h1 {
    position: static;
    width: 408px;
    height: 152px;
    left: 0px;
    top: 0px;
    font-family: ${Tokens.font.family.barlow};
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    color: #FFFFFF;
    line-height: 95%;
    text-transform: uppercase;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px 40px 0px;
  }
  .copy {
    position: static;
    width: 408px;
    height: 125px;
    left: 0px;
    top: 192px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 40px 0px;
  }
`

export const BlockImg = styled.div`
  position: absolute;
  width: 632px;
  height: 505px;
  left: 650px;
  top: 2530px;
  margin: 0px;
  img {
    border-radius: 8px;
  }
`
