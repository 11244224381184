import styled from "styled-components"
import { Tokens } from "../Theme/Tokens"


export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`

export const Heading = styled.div`
  font-family: ${Tokens.font.family.barlow};
  font-style: normal;
  text-align: center;
  text-transform: uppercase;

  h1 {
    font-size: 80px;
    line-height: 95%;
    @media (min-width: ${Tokens.screen.medium}) {

    }
  }
`

export const AvatarImage1 = styled.div`
  width: 315px;
  margin: 0;

  @media (min-width: ${Tokens.screen.medium}) {

  }
`

export const ChatImage = styled.div`
  width: 431px;
  margin: 0;

  @media (min-width: ${Tokens.screen.medium}) {

  }
`

export const AvatarImage2 = styled.div`
  width: 315px;
  margin: 0;

  @media (min-width: ${Tokens.screen.medium}) {

  }
`
