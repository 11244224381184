import React from 'react'
import styled from 'styled-components'

const FadedStarComponent = styled.div`
	display: block;
	width: ${props => props.width ?? "737px" };
	height: ${props => props.height ?? "737px"};
	background: rgba(255, 255, 255, 0.04);
	position: ${props => props.position?.type ?? "absolute"};
	top: ${props => props.position?.top ?? "initial" };
	right: ${props => props.position?.right ?? "initial"};
	bottom: ${props => props.position?.bottom ?? "initial"};
	left: ${props => props.position?.left ?? "initial"};
	z-index: ${props => props.zIndex ?? 0};
	border-radius: 112px;
	transform: rotate(26deg);
	transition all 1s cubic-bezier(0.2, 1, 1, 1);

	&::before {
		width: ${props => props.width ?? "737px" };
		height: ${props => props.height ?? "737px"};
		transform: translate(-50%, -50%) rotate(-46deg);
		content: "";
		border-radius: 112px;
		background: rgba(255, 255, 255, 0.04);
		position: absolute;
		top: 50%;
		left: 50%;
		transition all 1s cubic-bezier(0.2, 1, 1, 1);
	}
`

/**
 * Render a faded/blurred ball shaped element
 * @param {string} width
 * @param {string} height
 * @param {object|string} position
 * @param {string} transform
 * @param {number} zIndex
 * @returns 
 */
const FadedStar = ({ref, className, width, height, position, zIndex, dataProps}) => {
	return (
		<FadedStarComponent
			ref={ref}
			width={width}
			height={height}
			position={position}
			className={className}
			zIndex={zIndex}
			{...dataProps}
		/>
	)
}

export default FadedStar