import { useEffect, useState } from "react"

export default function useOnlyRenderIfBrowser() {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  return isBrowser
}
