import React from 'react'
import styled from 'styled-components'

const ContainerStyled = styled.div`
	width: 100%;
	display: block;
	margin: 0 auto;
	padding: 0 30px;
	position: relative;
	z-index: 1;

	@media ( min-width: ${props => (props.customWidth ? props.customWidth + 30 : 1170)}px) {
		width: ${props => props.customWidth}px;
		padding: 0;
	}

	${props => (props.customWidth === "100%") && "width: 100%"}
`

/**
 * Render a window-centered container (grid layout)
 * @param {number} customWidth
 * @param {ReactElement / Object} children 
 * @returns 
 */
const Container = ({className, customWidth, children}) => {
	return (
		<ContainerStyled
			className={className}
			customWidth={customWidth ?? 1170}
		>
			{children}
		</ContainerStyled>
	)	
}

export default Container