import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Context } from "../Context"
import { Theme } from "../../components"
import * as Styles from "./styles"


const StoriesModal = ({ isVisible }) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "header-logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      wp: wpPage(slug: { eq: "header" }) {
        headerFields {
          headerFields {
            languageValue {
              name
              slug
            }
            linkGroup {
              color
              label
              link
            }
          }
        }
      }
    }
  `)

  return (
    <Context.Consumer>
      {({ language, toggleStoriesModalIsVisible }) => {
        const fields = data.wp.headerFields.headerFields.find(row =>
          row.languageValue.slug.includes(language)
        )
        return isVisible ? (
          <>



            <Styles.Modal>


              <Styles.MenuHeader>
                <Styles.HeaderLogo>
                  <Link to={language === "pt" ? "/pt/" : "/"}>
                    <GatsbyImage
                      image={data.logo.childImageSharp.gatsbyImageData}
                      alt="Wanna"
                      eager="true"
                    />
                  </Link>
                </Styles.HeaderLogo>
              </Styles.MenuHeader>
              <Styles.LinkGroup>
                {[...fields.linkGroup].map(link => (
                  <Link key={link.label} className={link.color} to={link.link}>
                    {link.label}
                  </Link>
                ))}
              </Styles.LinkGroup>

              <Styles.Language>
                <Theme.ButtonGroup>
                  <Theme.Button>
                    EN
                  </Theme.Button>
                  <Theme.Button>
                    PT
                  </Theme.Button>
                </Theme.ButtonGroup>
              </Styles.Language>

              <Styles.Close onClick={() => toggleStoriesModalIsVisible(false)}>
                &times;
              </Styles.Close>



            </Styles.Modal>

            <Styles.ModalBackdrop
              onClick={e => {
                e.preventDefault()
                toggleStoriesModalIsVisible(false)
              }}
            ></Styles.ModalBackdrop>



          </>
        ) : null
      }}
    </Context.Consumer>
  )
}


export default StoriesModal
