// extracted by mini-css-extract-plugin
export var how = "style-module--how--2HMGu";
export var title = "style-module--title--1gG-V";
export var base__image = "style-module--base__image--22wA7";
export var conversation = "style-module--conversation--3vs4l";
export var conversationInactive = "style-module--conversation--inactive--1qFhM";
export var conversation__column = "style-module--conversation__column--BSda4";
export var conversation__guy = "style-module--conversation__guy--3j3UL";
export var conversation__bubbles = "style-module--conversation__bubbles--3bw1W";
export var bubble = "style-module--bubble--36B4L";
export var bubblePurple = "style-module--bubble--purple--1wdJD";
export var bubbleWhite = "style-module--bubble--white--1GrOU";
export var bubbleTyping = "style-module--bubble--typing--sZgOd";
export var jump = "style-module--jump--3Muwl";
export var slider = "style-module--slider--3xl1M";
export var sliderActive = "style-module--slider--active--2gFLM";
export var slide = "style-module--slide--2ttnr";
export var slide__image = "style-module--slide__image--3gnew";
export var chat = "style-module--chat--3TIQf";
export var avatar = "style-module--avatar--Du-nH";
export var slide__content = "style-module--slide__content--7dB7Z";
export var content = "style-module--content--1pHaW";
export var conversation__guyRight = "style-module--conversation__guy--right--11GEp";
export var glowYellow = "style-module--glowYellow--1Q_5P";
export var glowPurple = "style-module--glowPurple--2noVI";
export var roundedBox = "style-module--roundedBox--2Dk9H";
export var fadedStar = "style-module--fadedStar--1s5qe";
export var howActive = "style-module--how--active--2hGbB";