// extracted by mini-css-extract-plugin
export var hero = "style-module--hero--3GzLJ";
export var hero__column = "style-module--hero__column--3yCG3";
export var content__group = "style-module--content__group--7tT48";
export var image__container = "style-module--image__container--2Uxay";
export var jump = "style-module--jump--kT3rV";
export var image__containerMobile = "style-module--image__container--mobile--b69O9";
export var glowYellow = "style-module--glowYellow--38F8i";
export var glowPurple = "style-module--glowPurple--3bjdw";
export var fadedStar = "style-module--fadedStar--2Znai";
export var heroActive = "style-module--hero--active--Q3Ks9";
export var roundedBox = "style-module--roundedBox--aVyAP";