import React, { useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
// import addToMailchimp from "gatsby-plugin-mailchimp"

import { Button, Wrap } from "../Theme/Theme"
import { Tokens } from "../Theme/Tokens"

const FriendSignup = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [email2, setEmail2] = useState("")
  const [name2, setName2] = useState("")
  const [email3, setEmail3] = useState("")
  const [name3, setName3] = useState("")

  const handleEmailSubmit = () => {
    // if (!email || !name) return
    // email && addToMailchimp(email, { FNAME: name ? name : "" })
    // email2 && addToMailchimp(email2, { FNAME: name2 ? name2 : "" })
    // email3 && addToMailchimp(email3, { FNAME: name3 ? name3 : "" })
    //
    // return navigate("/pt/obrigado")
  }

  return (
    <Container id="scrollTo--PT-Optin">
      <Wrap size="narrow">
        <div className="text">
          <p className="gold">Disponível na app store e para android.</p>
          <h2>Faça parte e ainda concorra à prêmios!</h2>
          <p className="subTitle">
            Inscreva você e seus amigos e concorra à uma camisa oficial do seu
            time.
          </p>
        </div>

        <div className="fields">
          <input
            type="text"
            value={name}
            onChange={e => setName(e.currentTarget.value)}
            placeholder="Nome"
          />

          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.currentTarget.value)}
            placeholder="Email"
          />
        </div>

        <svg
          className="plus"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
            fill={Tokens.color.gold}
          />
        </svg>

        <div className="fields half">
          <input
            type="text"
            value={name2}
            onChange={e => setName2(e.currentTarget.value)}
            placeholder="Amigo #1"
          />

          <input
            type="email"
            value={email2}
            onChange={e => setEmail2(e.currentTarget.value)}
            placeholder="Email Amigo #1"
          />
        </div>

        <div className="fields half">
          <input
            type="text"
            value={name3}
            onChange={e => setName3(e.currentTarget.value)}
            placeholder="Amigo #2"
          />

          <input
            type="email"
            value={email3}
            onChange={e => setEmail3(e.currentTarget.value)}
            placeholder="Email Amigo #2"
          />
        </div>

        <YellowButton onClick={() => handleEmailSubmit()}>
          TÔ DENTRO!
        </YellowButton>
      </Wrap>
    </Container>
  )
}

const Container = styled.div`
  color: ${Tokens.color.white};
  padding: calc(${Tokens.rhythm} * 2) 0 calc(${Tokens.rhythm} * 4);
  background-color: ${Tokens.color.purple};
  position: relative;

  .text {
    max-width: 500px;
    margin: auto;
    text-align: center;
  }

  p {
    text-align: center;

    &.gold {
      color: ${Tokens.color.gold};
      margin-bottom: calc(${Tokens.rhythm} * 2);
    }

    &.subTitle {
      font-size: ${Tokens.font.size.large};
      margin-bottom: ${Tokens.rhythm};
    }
  }

  h2 {
    text-align: center;
    font-size: ${Tokens.font.size.ultraMobile};

    @media (min-width: ${Tokens.screen.medium}) {
      font-size: ${Tokens.font.size.ultra};
    }
  }

  input {
    width: 100%;
    padding: calc(${Tokens.rhythm} / 2) ${Tokens.rhythm};
    color: ${Tokens.color.white};
    background-color: transparent;
    border-radius: ${Tokens.border.radius};
    border: 1px solid ${Tokens.color.white};

    &::-webkit-input-placeholder {
      color: ${Tokens.color.white};
      font-weight: ${Tokens.font.weight.normal};
    }
    &:-moz-placeholder {
      color: ${Tokens.color.white};
      opacity: 1;
      font-weight: ${Tokens.font.weight.normal};
    }
    &::-moz-placeholder {
      color: ${Tokens.color.white};
      opacity: 1;
      font-weight: ${Tokens.font.weight.normal};
    }
    &:-ms-input-placeholder {
      color: ${Tokens.color.white};
      font-weight: ${Tokens.font.weight.normal};
    }
    &::-ms-input-placeholder {
      color: ${Tokens.color.white};
      font-weight: ${Tokens.font.weight.normal};
    }
    &::placeholder {
      color: ${Tokens.color.white};
      font-weight: ${Tokens.font.weight.normal};
    }
  }

  .plus {
    display: block;
    margin: calc(-${Tokens.rhythm} / 2) auto calc(${Tokens.rhythm} / 2);
  }

  .fields {
    margin-bottom: ${Tokens.rhythm};

    input + input {
      margin-top: ${Tokens.rhythm};
    }
  }

  .fields.half {
    @media (min-width: ${Tokens.screen.medium}) {
      display: flex;
      justify-content: space-between;

      input {
        width: 48%;
      }

      input + input {
        margin-top: 0;
      }
    }
  }

  .feedback {
    font-size: ${Tokens.font.size.extra};
    color: ${Tokens.color.gold};
  }
`

// const Background = styled.div`
//   position: absolute;
//   width: 400%;
//   height: 100%;
//   top: 0;
//   left: 50%;
//   border-radius: 50%;
//   background-image: radial-gradient(
//     rgba(255, 255, 255, 0.3) 0,
//     rgba(255, 255, 255, 0) 100%
//   );
//   background-color: ${Tokens.color.purple};
//   transform: translate(-50%, 0);
//   z-index: ${Tokens.z.behind};

//   @media (min-width: ${Tokens.screen.medium}) {
//     width: 300%;
//   }
// `

const YellowButton = styled(Button)`
  background-color: ${Tokens.color.gold};
  color: ${Tokens.color.purple};
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: ${Tokens.font.size.large};
`

export default FriendSignup
