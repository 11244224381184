import React from 'react'

export const Play = ({className, onClick}) => {
	return (
		<svg onClick={onClick} className={className} width="37" height="47" viewBox="0 0 37 47" fill="none">
			<path d="M35.3692 21.3593L4.44267 0.432816C4.06119 0.174426 3.61781 0.0259155 3.15976 0.00309474C2.70171 -0.019726 2.24614 0.0839971 1.84156 0.303224C1.43699 0.52245 1.09856 0.848967 0.862311 1.248C0.626064 1.64704 0.500848 2.10366 0.5 2.56922V44.4351C0.501606 44.8996 0.627021 45.355 0.862946 45.7531C1.09887 46.1511 1.43651 46.4769 1.84006 46.696C2.24361 46.915 2.69802 47.0191 3.15511 46.9971C3.6122 46.9752 4.05493 46.8281 4.43634 46.5715L35.3692 25.645C35.717 25.41 36.0022 25.0914 36.1995 24.7176C36.3968 24.3438 36.5 23.9262 36.5 23.5021C36.5 23.078 36.3968 22.6605 36.1995 22.2867C36.0022 21.9128 35.717 21.5943 35.3692 21.3593Z" fill="white"/>
			<path d="M35.3692 21.3593L4.44267 0.432816C4.06119 0.174426 3.61781 0.0259155 3.15976 0.00309474C2.70171 -0.019726 2.24614 0.0839971 1.84156 0.303224C1.43699 0.52245 1.09856 0.848967 0.862311 1.248C0.626064 1.64704 0.500848 2.10366 0.5 2.56922V44.4351C0.501606 44.8996 0.627021 45.355 0.862946 45.7531C1.09887 46.1511 1.43651 46.4769 1.84006 46.696C2.24361 46.915 2.69802 47.0191 3.15511 46.9971C3.6122 46.9752 4.05493 46.8281 4.43634 46.5715L35.3692 25.645C35.717 25.41 36.0022 25.0914 36.1995 24.7176C36.3968 24.3438 36.5 23.9262 36.5 23.5021C36.5 23.078 36.3968 22.6605 36.1995 22.2867C36.0022 21.9128 35.717 21.5943 35.3692 21.3593Z" fill="url(#paint0_linear_3234_53819)"/>
			<defs>
			<linearGradient id="paint0_linear_3234_53819" x1="36.5" y1="47" x2="-9.44905" y2="33.2519" gradientUnits="userSpaceOnUse">
			<stop stopColor="#9339E9"/>
			<stop offset="1" stopColor="#3E2794"/>
			</linearGradient>
			</defs>
		</svg>
	)
}