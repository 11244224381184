import React from 'react'
import styled from 'styled-components'
import { Values } from '..'

const RoundedBoxComponent = styled.div`
	display: block;
	width: ${props => props.width ?? "753px" };
	height: ${props => props.height ?? "914px"};
	background: linear-gradient(208.89deg, var(--color-primary) 1.69%, var(--color-secondary) 63.59%);
	position: ${props => props.position?.type ?? "absolute"};
	top: ${props => props.position?.top ?? "initial" };
	right: ${props => props.position?.right ?? "initial"};
	bottom: ${props => props.position?.bottom ?? "initial"};
	left: ${props => props.position?.left ?? "initial"};
	z-index: ${props => props.zIndex ?? 0};
	border-radius: ${props => props.borderRadius ?? "96px"};
	transform: ${props => props.transform ?? "none"};
	transition all 1s cubic-bezier(0.2, 1, 1, 1);
`

/**
 * Render a box with rounded corners element
 * @param {string} color
 * @param {string} width
 * @param {string} height
 * @param {object|string} position
 * @param {string} transform
 * @param {number} zIndex
 * @returns 
 */
const RoundedBox = ({ref, className, color, width, height, position, transform, zIndex, dataProps}) => {
	return (
		<RoundedBoxComponent
			ref={ref}
			width={width}
			height={height}
			color={color}
			position={position}
			transform={transform}
			zIndex={zIndex}
			className={className}
		/>
	)
}

export default RoundedBox